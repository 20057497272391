// Основной цвет шрифта, кнопок, логотипа
$brand-color: #22534e;
$brand-accent: #437e79;
$alternate-accent-color: #d41342;

$height-topBanner: 35px;

$bg-color: #c8a180;
$bg-color-black: #241e20;
$bg-light: #fff5f6;
$hover-bg-color: #ecebeb;
$bg-accent-color: #c7133e;

// Основной текст описания
$black: #000;
$white: #fff;

// Font
$main-font: 'Montserrat', Helvetica, sans-serif;
$brand-font: 'Titan One', sans-serif;

// Transition
$transition-duration: 250ms;
$transition-function: cubic-bezier(0.4, 0, 0.2, 1);
$transition-style: $transition-duration $transition-function;

// Shadow
$img-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$img-shadow-accent: 0px 8px 30px rgba(212, 20, 67, 0.1);