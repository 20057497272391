.products {
	padding-top: 40px;
	padding-bottom: 40px;

	@include breakpointFrom(tablet) {
		padding-top: 60px;
		padding-bottom: 60px;

		background-image: url('../../images/beauty-png/lines.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 130%;
	}
}

.products__label-top {
	text-align: left;
	margin-left: 50px;

	@include breakpointFrom(tablet) {
		margin-left: 233px;
	}

	@include breakpointFrom(desktop) {
		margin-left: 480px;
	}
}

.products__title {
	font-family: $main-font;
	font-weight: 700;
	font-size: 36px;
	line-height: 1.15;
	text-align: center;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: $brand-color;

	@include breakpointFrom(tablet) {
		font-size: 48px;
	}

	@include breakpointFrom(desktop) {
		font-size: 58px;
	}
}

.products__label {
	font-family: $brand-font;
	font-size: 18px;
	line-height: 1.17;
	text-align: center;
	text-transform: uppercase;
	color: $brand-accent;
	margin-bottom: 35px;

	@include breakpointFrom(tablet) {
		margin-bottom: 84px;
		font-size: 26px;
	}

	@include breakpointFrom(desktop) {
		margin-bottom: 100px;
		font-size: 20px;
	}
}

.products__list {
	text-align: center;

	@include breakpointFrom(tablet) {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
}

.products-item {
	position: relative;
	display: flex;
	flex-direction: column;

	background-color: $brand-color;

	border-radius: 24px;
	padding: 40px 20px;
	width: 290px;
	height: 290px;
	cursor: pointer;

	margin-bottom: 10px;
	transition: all 0.3s linear;

	&:hover {
		box-shadow: $img-shadow-accent;
		transform: scale(1.02);
	}

	@include breakpointFrom(tablet) {
		width: calc((100% - 20px) / 2);

		margin: 0;
	}

	@include breakpointFrom(desktop) {
		width: calc((100% - 60px) / 4);
	}
}

.products-item__title {
	position: relative;
	font-family: $main-font;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.15;
	text-align: center;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: $white;
	margin-bottom: 30px;

	@include breakpointFrom(tablet) {
		font-size: 24px;
	}

	@include breakpointFrom(desktop) {
		font-size: 20px;
	}
}

.products-item__text {
	font-family: $main-font;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.52;
	text-align: center;
	letter-spacing: 0.04em;
	color: $white;
	margin-bottom: 22px;

	flex-grow: 1;

	@include breakpointFrom(desktop) {
		margin-bottom: 20px;
	}
}