.backdrop[data-modal='buynow'] {
	pointer-events: initial;

	.buynow {
		visibility: visible;
		opacity: 1;
		pointer-events: initial;
		transform: translate(-50%, -50%) scale(1);
	}
}

.buynow {
	max-width: calc(100% - 20px);
	width: 80%;
	background-color: rgba(194, 226, 151, 1);

	@include breakpointFrom(mobile) {
		// background-image: url('../images/hero-header/icecream1x-header-tablet.png');
		background-repeat: no-repeat;
		background-size: 570px;
		background-position: left 15% bottom;

		@media (min-device-pixel-ratio: 2),
		(min-resolution: 192dpi),
		(min-resolution: 2dppx) {
			//   background-image: url('../images/hero-header/icecream2x-header-tablet.png');
			background-repeat: no-repeat;
			background-size: 570px;
			background-position: center bottom;
		}
	}

	@media screen and (max-width: 767px) {
		max-width: 380px;
	}

	@media screen and (min-width: 800px) {
		max-width: 700px !important;
	}
}

.modal-container {
	display: flex;

	.desc {
		&-title {
			font-weight: 700;
			font-size: 28px;
			line-height: 1.2;
			letter-spacing: 0.04em;
			text-transform: uppercase;
			color: $brand-color;
			margin-bottom: 10px;

			@media screen and (max-width: 767px) {
				font-size: 18px;
				text-align: center;
			}
		}

		&-text {
			font-size: 18px;
			color: $white;
			max-width: 400px;
			margin-bottom: 20px;

			@media screen and (max-width: 767px) {
				font-size: 16px;
			}
		}

		.list {
			padding: 0;
			margin: 0;
			list-style: none;
			margin-bottom: 40px;

			.item {
				display: flex;
				align-items: center;
				font-size: 16px;
				color: $brand-color;
				margin-bottom: 10px;

				&::before {
					content: '';
					background-image: url("data:image/svg+xml,%3Csvg height='48' fill='%23fff' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h48v48h-48z' fill='none'/%3E%3Cpath d='M18 32.34l-8.34-8.34-2.83 2.83 11.17 11.17 24-24-2.83-2.83z'/%3E%3C/svg%3E");
					display: block;
					width: 20px;
					height: 20px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					margin-right: 10px;

				}
			}
		}

	}

	.img-wrap {
		img {
			display: block;
			max-width: 500px;
			width: 100%;
			height: auto;
			object-fit: contain;
			object-position: center;
			mask-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .8) 50%);
		}

		@media screen and (max-width: 767px) {
			display: none;
		}
	}
}