.icon {
	flex: 0 0 auto;
	display: block;
	width: 20px;
	height: 20px;

	fill: $white;

	&:hover {
		fill: $bg-color-black;
	}
}

.header {
	position: relative;
	margin-top: $height-topBanner;
	border-bottom: 1px solid lightgray;
}

.header-address {
	display: flex;
	align-items: center;
}


.nav-wrapper {
	background-color: white;
	transition: position 2s linear;

	&.fixed {
		position: fixed;
		z-index: 1;
		top: $height-topBanner;
		width: 100%;
		box-shadow: $img-shadow-accent;
		transition: all 2s linear;
	}
}

.header__nav {
	padding-top: 8px;
	padding-bottom: 8px;

	@media screen and (min-width: 768px) {
		display: flex;
		align-items: center;
	}

	@media screen and (min-width: 1280px) {
		justify-content: space-between;
	}
}

// Navigation menu

.nav {
	transition: makeTransition((opacity color));

	@media screen and (max-width: 767px) {
		position: fixed;
		z-index: 9;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;

		padding: 20px;
		background: $brand-accent;
		background: linear-gradient(to top,
				rgba(34, 83, 78, 1) 0%,
				rgba(67, 126, 121, 1) 100%);

		transform: translateX(100%);
		transition: transform 0.4s ease-in;

		.nav__link--active,
		.nav__link.primary {
			color: $bg-color-black;
		}

		& .contact__items {
			display: flex;
			flex-direction: column;
			gap: 20px;
			margin: 40px;
		}

		&.is-open {
			transform: translateX(0);
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1279px) {
		position: fixed;
		z-index: 9;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;

		padding: 20px;
		background: $brand-accent;
		background: linear-gradient(to top,
				rgba(34, 83, 78, 1) 0%,
				rgba(67, 126, 121, 1) 100%);

		transform: translateX(100%);
		transition: makeTransition(transform);

		& .contact__items {
			display: flex;
			gap: 20px;
			margin-left: 40px;
			margin-top: 40px;
		}

		.nav__link--active,
		.nav__link.primary {
			color: $bg-color-black;
		}

		&.is-open {
			transform: translateX(50%);

			+.button__menu {
				position: absolute;
				right: 35px;
			}
		}
	}

	@media screen and (min-width: 1280px) {
		& .contact__items {
			display: none;
		}
	}
}

.nav__items {
	@media screen and (max-width: 1279px) {
		padding-top: 50px;
	}

	@media screen and (min-width: 1280px) {
		display: flex;
		align-items: center;
	}
}

.nav__item {
	margin-right: 35px;

	&:last-child {
		margin-right: 0;
	}

	@media screen and (max-width: 1279px) {
		padding: 12px 20px;
	}
}

.nav__link {
	display: block;
	font-weight: 700;
	font-size: 16px;
	line-height: 1.2;
	text-transform: uppercase;

	color: $brand-color;

	transition: makeTransition(color);

	&--active {
		color: $alternate-accent-color;
	}

	&.primary {
		color: $alternate-accent-color;
	}

	&:hover {
		color: $alternate-accent-color;
	}

	@media screen and (max-width: 1279px) {
		padding: 12px 20px;
		color: $white;

		&--active {
			color: $bg-color-black;
		}
	}
}

// Contact

.contact__items {
	display: none;

	@media screen and (min-width: 1280px) {
		display: flex;
		align-items: center;
	}

	.contact__item {
		display: flex;
		align-items: center;

		&:hover .contact__icon,
		&:hover .contact__link {
			fill: $bg-color-black;
		}

		.contact__icon {
			fill: $brand-color;
		}

		@media screen and (max-width: 1280px) {
			.contact__icon {
				fill: white;
			}

			.contact__item:not(:first-child) {
				margin-left: 20px;
			}
		}
	}

	@media screen and (min-width: 1280px) {
		.contact__item:not(:first-child) {
			margin-left: 20px;
		}
	}

	.contact__link {
		display: flex;
		align-items: center;

		font-weight: 500;
		font-size: 12px;
		line-height: 1.2;
		text-align: center;
		letter-spacing: 0.02em;

		color: $brand-color;

		transition: $transition-style;

		&:hover {
			color: $bg-color-black;
		}

		@media screen and (max-width: 1279px) {
			color: white;

			&:hover {
				color: $bg-color-black;
			}
		}
	}

	.contact__icon {
		margin-right: 10px;
	}
}

// Burger menu

.button__menu {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	z-index: 10;

	border: none;
	width: 30px;
	height: 30px;

	margin-left: auto;

	padding: 0;
	background-color: transparent;
	cursor: pointer;

	@media screen and (max-width: 767px) {
		position: absolute;
		top: 35px;
		right: 50px;

		width: 30px;
		height: 30px;
	}

	@media screen and (min-width: 768px) {
		margin-left: auto;
	}

	@media screen and (min-width: 1280px) {
		display: none;
	}

	.button__line {
		display: inline-flex;
		z-index: 6;

		width: 24px;
		height: 2px;
		background-color: $brand-color;
		transition: makeTransition(color, opacity);

		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 24px;
			height: 2px;

			background-color: $brand-color;
			transition: all 0.4s;
		}

		&::before {
			content: '';
			top: 6px;
			left: 3px;
		}

		&::after {
			content: '';
			bottom: 6px;
			left: 3;
		}
	}

	&.is-open {
		.button__line {
			width: 0;

			&::before {
				content: '';
				top: 15px;
				left: 3;
				transform: rotate(45deg);
				background-color: $white;
			}

			&::after {
				content: '';
				transform: rotate(-45deg);
				bottom: 13px;
				left: 3;

				background-color: $white;
			}
		}
	}
}

.header__nav .button {
	border-radius: 22px;
	transition: makeTransition((opacity transform));

	@media screen and (max-width: 767px) {
		display: none;
	}
}

.nav.is-open {
	.button {
		width: 209px;

		@media screen and (max-width: 767px) {
			position: absolute;
			top: 60px;
			left: 50%;
			transform: translateX(-50%);
			display: block;
		}

		@media screen and (min-width: 768px) {
			position: absolute;
			top: 60px;
			left: 35%;
			transform: translateX(-100%);
			display: block;
		}

		@media screen and (min-width: 1280px) {
			display: none;
		}
	}
}

.nav.is-open~.button {
	@include breakpointFrom(tablet) {
		display: none;
	}

	@include breakpointFrom(desktop) {
		display: block;
	}
}

.button__icon {
	width: 10px;
	height: 10px;
	margin-left: 15px;
	fill: transparent;
	stroke: $brand-color;
}