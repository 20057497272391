.section-contacts {
	padding: 80px 0;

	@media screen and (max-width: 1280px) {
		padding: 20px 0;
	}

	.contact__list {
		display: flex;

		@media screen and (max-width: 992px) {
			display: block;
		}


		.contact__item {
			max-width: 50%;
			width: 100%;
			padding: 20px;

			@media screen and (max-width: 992px) {
				max-width: 100%;
				width: 100%;
			}

			.contact__title {
				text-align: left;
				font-size: 32px;
				margin-bottom: 10px;

				@media screen and (max-width: 776px) {
					text-align: center;
					font-size: 24px;
					margin-bottom: 20px;
				}
			}

			.text {
				font-size: 16px;
				line-height: 1.3;
				text-align: left;
				margin-bottom: 40px;

				@media screen and (max-width: 776px) {
					text-align: center;
				}
			}

			.address__list {
				display: flex;
				justify-content: space-between;

				@media screen and (max-width: 776px) {
					display: block;
				}

				.address__item {
					max-width: 50%;
					width: 100%;
					padding: 10px;

					@media screen and (max-width: 776px) {
						max-width: 100%;
					}

					table {
						border: none;
						margin-top: 10px;

						tbody {
							tr {
								td {
									padding: 5px 0;
									color: $black;
									text-align: left;
									font-size: 14px;
									border-bottom: transparent;
									background-color: transparent !important;

									&:hover {
										color: $black;
										background-color: transparent !important;
									}

									&:nth-child(2) {
										text-align: right;
									}
								}
							}
						}
					}
				}

				.address__title {
					font-style: normal;
					font-size: 18px;
					margin-bottom: 10px;
				}

				.list {

					&.soccial {
						margin-top: 10px;
						display: flex;
						gap: 10px;
					}

					.item {
						padding: 5px 0;

						.link {
							font-style: normal;
							display: block;
							text-decoration: none;
							font-size: 14px;
							color: $black;
							transition: all 0.3s ease;

							&:hover {
								color: $brand-color;
							}

							.icon {
								display: block;
								fill: black;
								width: 30px;
								height: 30px;
							}
						}
					}
				}

			}

			.img-thumb {
				position: relative;
				overflow: hidden;
				padding-bottom: 100%;
				box-shadow: $img-shadow;

				img {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
}