.goto-top {
	// display: none; /* Hidden by default */
	position: fixed;
	bottom: 20px;
	right: 30px;
	border: 2px solid $brand-accent;
	background-color: transparent;
	width: 50px;
	height: 50px;

	stroke: $brand-accent;
	fill: $brand-accent;
	color: $brand-accent;

	transition: makeTransition((transform));

	&.goto-top--hide {
		transform: translateX(200%);
	}

	&__icon {
		width: 20px;
		height: 20px;
		transform: rotate(-90deg);
	}
}