.footer {
	flex: 0 0 auto;
	font-style: normal;
	background-color: $brand-color;

	&-wrapper {
		padding: 20px 18px;

	}

	&__container {
		padding-top: 40px;
	}

	.footer__item {
		.item {
			margin-bottom: 10px;

			&:hover {
				.link {
					border-color: grey;
					color: grey;
				}
			}
		}

		.link {
			font-size: 16px;
			color: $white;
			padding: 7px 0;
			letter-spacing: 1px;
			transition: all 0.3s ease-in;
		}

	}
}

.rights-reserved {
	width: 100%;
	border-top: 1px solid $white;
	padding: 10px 0;

	.reserved-group {
		margin-bottom: 10px;
	}

	&__text {
		font-weight: 300;
		font-size: 12px;
		line-height: 1.52;
		text-align: center;
		color: $white;
	}

	.links {
		a {
			color: $white;
			font-weight: 300;
			font-size: 12px;
		}
	}

	.payment {
		display: flex;
		align-items: center;
		justify-content: center;

		.pay {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.footer__title {
	font-style: normal;
	color: white;
	margin-bottom: 30px;
}

@include breakpointTo(tablet) {
	.footer__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 18px;
		padding-right: 18px;
	}

	.handmade {
		margin-bottom: 40px;

		&__under-icon {
			width: 20px;
			height: 20px;
		}
	}

	.socials {
		&__link {
			width: 32px;
			height: 32px;
		}

		&__box:not(:last-child) {
			margin-right: 28px;
		}
	}

	.rights-reserved {
		display: block;
	}

	.footer__item:not(:last-child) {
		margin-bottom: 30px;
	}
}

@include breakpointFrom(tablet) {
	.footer__container {
		padding-left: 18px;
		padding-right: 18px;
	}

	.footer-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 40px 18px;

		.footer__item {
			padding-right: 10px;

			&:first-child {
				flex: 1 0 auto;
			}

			&:last-child {
				padding-right: 0;
			}

			.item {
				margin-bottom: 10px;

				&:hover {
					.link {
						border-color: grey;
						color: grey;
					}
				}
			}

			.link {
				font-size: 16px;
				color: $white;
				padding: 7px 0;
				letter-spacing: 1px;
				transition: all 0.3s ease-in;
			}

		}
	}

	.handmade {
		max-width: 370px;

		&__upper-text {
			font-size: 30px;
			line-height: 1.13;
			letter-spacing: 0.04em;
			margin-bottom: 12px;
		}

		&__under-text {
			font-size: 58px;
			line-height: 1.14;
			letter-spacing: 0.06em;
		}

		&__under-icon {
			width: 40px;
			height: 40px;
		}
	}

	.footer__title {
		text-align: left;
	}

	.socials {
		&__link {
			width: 34px;
			height: 34px;
		}

		&__box:not(:last-child) {
			margin-right: 30px;
		}
	}

	.rights-reserved {
		text-align: center;

		&__text {
			letter-spacing: 0.02em;
		}
	}
}

@include breakpointFrom(desktop) {
	.footer__container {
		padding-bottom: 60px;
	}

	.footer__item {
		flex-grow: 1;
	}

	.rights-reserved {
		margin-bottom: 0;

		.reserved-group {
			margin-bottom: 0;
		}

		.inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
		}
	}
}

.footer-address {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpointFrom(tablet) {
		display: block;

		.footer__title {
			text-align: left;
		}
	}

	.footer__contact {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-style: normal;

		& .contact__item {
			margin-left: 0;
			margin-bottom: 10px;

			.icon {
				fill: white;
			}

			&:hover .contact__link {
				color: $bg-color-black;
			}

			&:hover .icon {
				fill: $bg-color-black;
			}
		}

		.contact__link {
			color: white;
			font-size: 14px;
		}
	}
}

.contact__map-wrap {
	overflow: hidden;
	box-shadow: $img-shadow-accent;

	.google-map {
		display: block;
		width: 100% !important;
		height: 350px;
	}
}

.google-map {
	display: block;
	width: 100%;
	height: 250px;
}

.footer-social {
	margin: 0;
	margin-bottom: 20px;
	display: flex;
	align-items: center;

	.address-social__item {
		font-size: 14px;
		font-style: normal;
		color: rgb(255, 255, 255);
		margin-right: 7px;

		&:last-child {
			margin-right: 0;
		}
	}
}



.contact__sheddule {
	display: flex;
	flex-direction: column;

	.contact__item {
		margin-bottom: 10px;
	}

	.contact__sheddule-links {
		letter-spacing: 0.05rem;
		text-transform: uppercase;
		color: white;
	}

	.sheddule-time {
		font-size: 12px;
	}

	.contact__link {
		font-size: 14px;
		font-style: normal;
		color: white;
	}
}