*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

body {
  height: 100%;
  color: #000;
  background-color: #fff;
  flex-direction: column;
  font-family: Montserrat, Helvetica, sans-serif;
  font-weight: 500;
  display: flex;
  position: relative;
}

body:after {
  content: "";
  opacity: 0;
  transition: all .2s linear;
}

body.no-scroll {
  height: 100vh;
  overflow: hidden;
}

body.no-scroll:after {
  content: "";
  width: 100vw;
  height: 100vh;
  opacity: 1;
  background-color: #00000080;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 1280px) {
  body.no-scroll.menu-opened {
    overflow: auto;
  }
}

.main {
  flex: 1 0 auto;
}

.js-hidden {
  filter: blur(5px);
  opacity: 0;
  transition: all 1s linear;
  transform: translateY(10%);
}

.js-hidden.show {
  filter: blur();
  opacity: 1;
  transform: translateY(0);
}

h1, h2, h3, h4, h5, h6, ul, p {
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

a {
  cursor: pointer;
  text-decoration: none;
}

ul, ol {
  list-style: none;
}

.container {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 320px) {
  .container {
    max-width: 340px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.title {
  letter-spacing: .02em;
  text-transform: uppercase;
  color: #22534e;
  text-shadow: 0 3px 8px #0000003d;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
}

.text {
  text-align: center;
  color: #437e79;
  font-size: 18px;
  line-height: 1.17;
}

.subtext {
  color: #437e79;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.62;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.button {
  height: 40px;
  cursor: pointer;
  color: #22534e;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.d-none {
  display: none !important;
}

.button:hover, .button:focus {
  background-color: #ecebeb;
}

.button--accent {
  color: #fff;
  background-color: #22534e;
}

.button--round {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
}

.button--alternate {
  color: #fff;
  background-color: #d41342;
}

.button--accent:hover, .button--accent:focus, .button--alternate:hover, .button--alternate:focus {
  background-color: #c7133e;
}

.button--bold {
  height: 44px;
  font-size: 16px;
  font-weight: 700;
}

.social-link {
  width: 34px;
  height: 34px;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.social-link:hover, .social-link:focus {
  background-color: #22534e;
}

.social-link__icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
}

.callback {
  width: 60px;
  height: 60px;
  font-size: .7em;
  font-weight: light;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -1px;
  color: #fff;
  cursor: pointer;
  background: #d41342;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: Trebuchet MS, sans-serif;
  line-height: 1.3;
  animation: pulse 1.5s infinite;
  display: flex;
  position: fixed;
  top: 140px;
  right: 35px;
  box-shadow: 0 0 #d4134280;
}

.callback:hover {
  animation: none;
}

@media only screen and (min-width: 1280px) {
  .callback {
    width: 75px;
    height: 75px;
    font-size: .8em;
    top: 180px;
    right: 50px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px #d4134200;
  }

  100% {
    transform: scale(.9);
    box-shadow: 0 0 #d4134200;
  }
}

.icon {
  width: 20px;
  height: 20px;
  fill: #fff;
  flex: none;
  display: block;
}

.icon:hover {
  fill: #241e20;
}

.header {
  border-bottom: 1px solid #d3d3d3;
  margin-top: 35px;
  position: relative;
}

.header-address {
  align-items: center;
  display: flex;
}

.nav-wrapper {
  background-color: #fff;
  transition: position 2s linear;
}

.nav-wrapper.fixed {
  z-index: 1;
  width: 100%;
  transition: all 2s linear;
  position: fixed;
  top: 35px;
  box-shadow: 0 8px 30px #d414431a;
}

.header__nav {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .header__nav {
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .header__nav {
    justify-content: space-between;
  }
}

.nav {
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), color .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (max-width: 767px) {
  .nav {
    z-index: 9;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to top, #22534e 0%, #437e79 100%);
    padding: 20px;
    transition: transform .4s ease-in;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  .nav .nav__link--active, .nav .nav__link.primary {
    color: #241e20;
  }

  .nav .contact__items {
    flex-direction: column;
    gap: 20px;
    margin: 40px;
    display: flex;
  }

  .nav.is-open {
    transform: translateX(0);
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .nav {
    z-index: 9;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to top, #22534e 0%, #437e79 100%);
    padding: 20px;
    transition: transform .25s cubic-bezier(.4, 0, .2, 1);
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  .nav .contact__items {
    gap: 20px;
    margin-top: 40px;
    margin-left: 40px;
    display: flex;
  }

  .nav .nav__link--active, .nav .nav__link.primary {
    color: #241e20;
  }

  .nav.is-open {
    transform: translateX(50%);
  }

  .nav.is-open + .button__menu {
    position: absolute;
    right: 35px;
  }
}

@media screen and (min-width: 1280px) {
  .nav .contact__items {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .nav__items {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1280px) {
  .nav__items {
    align-items: center;
    display: flex;
  }
}

.nav__item {
  margin-right: 35px;
}

.nav__item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1279px) {
  .nav__item {
    padding: 12px 20px;
  }
}

.nav__link {
  text-transform: uppercase;
  color: #22534e;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  transition: color .25s cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.nav__link--active, .nav__link.primary, .nav__link:hover {
  color: #d41342;
}

@media screen and (max-width: 1279px) {
  .nav__link {
    color: #fff;
    padding: 12px 20px;
  }

  .nav__link--active {
    color: #241e20;
  }
}

.contact__items {
  display: none;
}

@media screen and (min-width: 1280px) {
  .contact__items {
    align-items: center;
    display: flex;
  }
}

.contact__items .contact__item {
  align-items: center;
  display: flex;
}

.contact__items .contact__item:hover .contact__icon, .contact__items .contact__item:hover .contact__link {
  fill: #241e20;
}

.contact__items .contact__item .contact__icon {
  fill: #22534e;
}

@media screen and (max-width: 1280px) {
  .contact__items .contact__item .contact__icon {
    fill: #fff;
  }

  .contact__items .contact__item .contact__item:not(:first-child) {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .contact__items .contact__item:not(:first-child) {
    margin-left: 20px;
  }
}

.contact__items .contact__link {
  text-align: center;
  letter-spacing: .02em;
  color: #22534e;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.contact__items .contact__link:hover {
  color: #241e20;
}

@media screen and (max-width: 1279px) {
  .contact__items .contact__link {
    color: #fff;
  }

  .contact__items .contact__link:hover {
    color: #241e20;
  }
}

.contact__items .contact__icon {
  margin-right: 10px;
}

.button__menu {
  z-index: 10;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0;
  display: inline-flex;
  position: relative;
}

@media screen and (max-width: 767px) {
  .button__menu {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35px;
    right: 50px;
  }
}

@media screen and (min-width: 768px) {
  .button__menu {
    margin-left: auto;
  }
}

@media screen and (min-width: 1280px) {
  .button__menu {
    display: none;
  }
}

.button__menu .button__line {
  z-index: 6;
  width: 24px;
  height: 2px;
  transition: color 250ms cubic-bezier(.4, 0, .2, 1) opacity;
  background-color: #22534e;
  display: inline-flex;
}

.button__menu .button__line:before, .button__menu .button__line:after {
  content: "";
  width: 24px;
  height: 2px;
  background-color: #22534e;
  transition: all .4s;
  position: absolute;
}

.button__menu .button__line:before {
  content: "";
  top: 6px;
  left: 3px;
}

.button__menu .button__line:after {
  content: "";
  bottom: 6px;
  left: 3px;
}

.button__menu.is-open .button__line {
  width: 0;
}

.button__menu.is-open .button__line:before {
  content: "";
  background-color: #fff;
  top: 15px;
  left: 3px;
  transform: rotate(45deg);
}

.button__menu.is-open .button__line:after {
  content: "";
  background-color: #fff;
  bottom: 13px;
  left: 3px;
  transform: rotate(-45deg);
}

.header__nav .button {
  border-radius: 22px;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), transform .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (max-width: 767px) {
  .header__nav .button {
    display: none;
  }
}

.nav.is-open .button {
  width: 209px;
}

@media screen and (max-width: 767px) {
  .nav.is-open .button {
    display: block;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 768px) {
  .nav.is-open .button {
    display: block;
    position: absolute;
    top: 60px;
    left: 35%;
    transform: translateX(-100%);
  }
}

@media screen and (min-width: 1280px) {
  .nav.is-open .button {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .nav.is-open ~ .button {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .nav.is-open ~ .button {
    display: block;
  }
}

.button__icon {
  width: 10px;
  height: 10px;
  fill: #0000;
  stroke: #22534e;
  margin-left: 15px;
}

.hero {
  background-image: linear-gradient(#22534e66, #22534eb3), url("desktop_hero@2x.1f98a0bc.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .hero {
    background-image: linear-gradient(#22534e66, #22534eb3), url("desktop_hero@1.5x.b6a16692.webp");
  }
}

@media screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), screen and (min-width: 1280px) and (min-resolution: 192dpi), screen and (min-width: 1280px) and (min-resolution: 2x) {
  .hero {
    background-image: linear-gradient(#22534e66, #22534eb3), url("desktop_hero@2x.1f98a0bc.webp");
  }
}

.hero__body {
  padding-top: 116px;
  padding-bottom: 158px;
}

@media screen and (min-width: 768px) {
  .hero__body {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1280px) {
  .hero__body {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

.hero__items {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .hero__items {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .hero__items {
    margin-bottom: 40px;
  }
}

.hero__title {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .hero__title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1279px) {
  .hero__title {
    max-width: 462px;
    font-size: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .hero__title {
    max-width: 800px;
    margin-bottom: 24px;
    font-size: 54px;
  }

  .hero__title--accent {
    max-width: 462px;
    font-family: Montserrat, Helvetica, sans-serif;
  }
}

.hero__title--accent {
  letter-spacing: 0;
  text-transform: none;
  color: #fff;
  padding: 10px 0;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .hero__title--accent {
    font-size: 16px;
  }
}

@media screen and (min-width: 1280px) {
  .hero__title--accent {
    max-width: 650px;
  }
}

.hero__body .button {
  height: 32px;
  margin-bottom: 10px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
}

@media screen and (min-width: 1280px) {
  .hero__body .button {
    height: 40px;
    margin-bottom: 0;
    padding: 11px 22px;
    font-size: 14px;
    line-height: 1.2;
  }

  .hero__body .button.button + .button {
    margin-left: 8px;
  }
}

.hero__text {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.hero__text--accent {
  padding-right: 10px;
}

@media screen and (min-width: 1280px) {
  .hero__text {
    font-size: 14px;
    line-height: 1.3;
  }

  .hero__text--accent {
    padding-right: 20px;
  }
}

.hero__product {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 767px) {
  .hero__product {
    display: none;
  }
}

.product__text {
  max-width: 300px;
  color: #fff;
  margin-bottom: 7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (min-width: 1280px) {
  .product__text {
    max-width: 300px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 140%;
  }
}

.product__body .button {
  width: 18px;
  height: 18px;
  padding: 0;
}

@media screen and (min-width: 1280px) {
  .product__body .button {
    width: 26px;
    height: 26px;
    padding: 0;
  }
}

.product__icon {
  width: 10px;
  height: 10px;
  fill: #22534e;
  padding: 0;
}

.about {
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .about {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767.98px) {
  .about {
    background-image: url("spilledmilk1x-mobile.1692a32f.png");
  }
}

@media only screen and (max-width: 767.98px) and (min-device-pixel-ratio: 2), only screen and (max-width: 767.98px) and (min-resolution: 192dpi), only screen and (max-width: 767.98px) and (min-resolution: 2x) {
  .about {
    background-image: url("spilledmilk2x-mobile.86f07583.png");
  }
}

@media only screen and (min-width: 1280px) {
  .about {
    background-image: url("spilledmilk1x-descktop.5ec12f69.png");
    background-position: bottom;
    background-size: contain;
  }
}

@media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2x) {
  .about {
    background-image: url("spilledmilk1x-descktop.5ec12f69.png");
  }
}

@media only screen and (min-width: 768px) {
  .about__thumb {
    display: flex;
  }
}

.about__label {
  text-align: center;
  text-transform: uppercase;
  color: #437e79;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.17;
}

@media only screen and (min-width: 768px) {
  .about__label {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1280px) {
  .about__label {
    font-size: 22px;
  }
}

.about__title {
  color: #22534e;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .about__title {
    margin-bottom: 40px;
    font-size: 32px;
  }
}

@media only screen and (min-width: 1280px) {
  .about__title {
    margin-bottom: 60px;
    font-size: 48px;
  }
}

.about__block {
  max-width: 310px;
  width: 100%;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .about__block {
    max-width: 335px;
    margin-top: 0;
    margin-left: 32px;
  }
}

@media only screen and (min-width: 1280px) {
  .about__block {
    max-width: 440px;
    margin-left: 160px;
  }
}

.about__text {
  color: #437e79;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.62;
}

.about__text:not(:last-of-type) {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .about__text {
    padding-right: 25px;
    font-size: 14px;
    line-height: 1.77;
  }
}

@media only screen and (min-width: 1280px) {
  .about__text {
    padding-right: 0;
    font-size: 16px;
  }

  .about__text:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.button--about {
  width: 176px;
  height: 44px;
  margin-top: 20px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .button--about {
    margin-top: 40px;
  }
}

.about__icon {
  width: 15px;
  height: 15px;
  fill: #0000;
  stroke: #22534e;
  margin-left: 15px;
}

.img__wrapper {
  max-width: 290px;
  max-height: 300px;
  width: 100%;
  background-color: #0000;
  border: 1px solid #437e79;
  border-radius: 20px;
  position: relative;
}

.img__wrapper .about__data {
  background-color: #fff;
  padding: 5px;
  position: absolute;
  bottom: 0;
  right: 30px;
}

.img__wrapper .about__data .about__name {
  font-size: 14px;
}

.img__wrapper .about__data .about__subname {
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  .img__wrapper {
    max-width: 390px;
    max-height: 350px;
    width: 100%;
  }

  .img__wrapper .about__data {
    bottom: 0;
    right: 50px;
  }

  .img__wrapper .about__data .about__name {
    font-size: 16px;
  }

  .img__wrapper .about__data .about__subname {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1280px) {
  .img__wrapper {
    max-width: 440px;
    max-height: 450px;
    width: 100%;
  }

  .img__wrapper .about__data {
    bottom: 20px;
    right: 0;
  }

  .img__wrapper .about__data .about__name {
    font-size: 18px;
  }

  .img__wrapper .about__data .about__subname {
    font-size: 16px;
  }
}

.about__img {
  max-width: 290px;
  max-height: 300px;
  width: 100%;
  border-radius: 20px;
  display: block;
  overflow: hidden;
  transform: translate(-15px, 15px);
  box-shadow: 0 3px 8px #0000003d;
}

.about__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

@media only screen and (min-width: 768px) {
  .about__img {
    max-width: 350px;
    max-height: 350px;
    width: 100%;
    transform: translate(-15px, 30px);
  }
}

@media only screen and (min-width: 1280px) {
  .about__img {
    max-width: 450px;
    max-height: 450px;
    width: 100%;
    margin-left: 60px;
  }
}

.advantages {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .advantages {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) {
  .advantages__wrapper {
    align-items: center;
    gap: 5px;
    display: flex;
  }
}

.advantages-box {
  width: 100%;
  height: 300px;
  background-image: url("masters.bee26bb9.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 40px 20px;
  box-shadow: 0 8px 30px #d414431a;
}

@media only screen and (min-width: 768px) {
  .advantages-box {
    width: 50%;
    height: 300px;
    margin: 0;
    padding: 50px 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .advantages-box {
    width: 650px;
    height: 450px;
    padding: 80px 40px;
  }
}

.advantages-box__text, .advantages-box__title, .advantages-box__subtext {
  text-align: left;
  color: #fff;
}

.advantages-box__text {
  letter-spacing: .02em;
  font-size: 12px;
  line-height: 1.4;
}

@media only screen and (min-width: 768px) {
  .advantages-box__text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1280px) {
  .advantages-box__text {
    font-size: 18px;
  }
}

.advantages-box__title {
  color: #fff;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .advantages-box__title {
    margin-bottom: 13px;
    font-size: 32px;
    line-height: 1.15;
  }
}

@media only screen and (min-width: 1280px) {
  .advantages-box__title {
    margin-bottom: 16px;
    font-size: 58px;
    line-height: 1.14;
  }
}

.advantages-box__subtext {
  letter-spacing: .02em;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

@media only screen and (min-width: 768px) {
  .advantages-box__subtext {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1280px) {
  .advantages-box__subtext {
    font-size: 18px;
  }
}

.advantages__list {
  display: block;
}

@media only screen and (min-width: 768px) {
  .advantages__list {
    display: flex;
  }
}

.advantages__item {
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 320px) {
  .advantages__item {
    width: 230px;
    padding-top: 60px;
  }

  .advantages__item:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .advantages__item {
    width: 180px;
    padding-top: 86px;
  }

  .advantages__item:not(:last-child) {
    margin-right: 22px;
  }
}

@media only screen and (min-width: 1280px) {
  .advantages__item {
    width: 188px;
    padding-top: 90px;
  }
}

.advantages__item:before {
  content: " ";
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  position: absolute;
}

.advantages__text {
  letter-spacing: .02em;
  color: #000;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.67;
}

@media screen and (min-width: 768px) {
  .advantages__text {
    font-size: 14px;
    line-height: 1.71;
  }
}

@media screen and (min-width: 1280px) {
  .advantages__text {
    font-size: 16px;
    line-height: 1.75;
  }
}

.center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .center {
    margin-left: 3px;
  }
}

@media only screen and (min-width: 1280px) {
  .center {
    width: 100%;
  }
}

.wrapper {
  width: 17em;
  margin-bottom: 20px;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    width: 50em;
  }
}

@media only screen and (min-width: 1280px) {
  .wrapper {
    width: 50em;
  }
}

.wrapper > .inner {
  gap: 1em;
  transition: all 1s ease-in-out;
  display: flex;
}

.card {
  width: 160px;
  height: 350px;
  border-radius: .5em;
  margin-bottom: 40px;
  overflow: hidden;
}

.card .masters__inner {
  padding-bottom: 120%;
  position: relative;
  overflow: hidden;
}

.card .masters__inner img {
  object-fit: cover;
  object-position: center;
  height: 120%;
  width: 120%;
  display: block;
  position: absolute;
  inset: 0 0 0 -5%;
}

.card .masters__content {
  flex-direction: column;
  padding: 10px;
  display: flex;
}

.card .masters__content .masters__title {
  letter-spacing: .06em;
  color: #000;
  flex: auto;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 2;
}

.card .masters__content .masters__subtitle {
  color: gray;
  letter-spacing: .03em;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

@media only screen and (min-width: 768px) {
  .card {
    width: 100%;
    height: 350px;
    margin: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .card {
    width: 200px;
    min-height: 357px;
    height: 100%;
  }
}

.products {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .products {
    background-image: url("lines.d296ca4c.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 130%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.products__label-top {
  text-align: left;
  margin-left: 50px;
}

@media only screen and (min-width: 768px) {
  .products__label-top {
    margin-left: 233px;
  }
}

@media only screen and (min-width: 1280px) {
  .products__label-top {
    margin-left: 480px;
  }
}

.products__title {
  text-align: center;
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #22534e;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.15;
}

@media only screen and (min-width: 768px) {
  .products__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1280px) {
  .products__title {
    font-size: 58px;
  }
}

.products__label {
  text-align: center;
  text-transform: uppercase;
  color: #437e79;
  margin-bottom: 35px;
  font-family: Titan One, sans-serif;
  font-size: 18px;
  line-height: 1.17;
}

@media only screen and (min-width: 768px) {
  .products__label {
    margin-bottom: 84px;
    font-size: 26px;
  }
}

@media only screen and (min-width: 1280px) {
  .products__label {
    margin-bottom: 100px;
    font-size: 20px;
  }
}

.products__list {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .products__list {
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
  }
}

.products-item {
  width: 290px;
  height: 290px;
  cursor: pointer;
  background-color: #22534e;
  border-radius: 24px;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 40px 20px;
  transition: all .3s linear;
  display: flex;
  position: relative;
}

.products-item:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 30px #d414431a;
}

@media only screen and (min-width: 768px) {
  .products-item {
    width: calc(50% - 10px);
    margin: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .products-item {
    width: calc(25% - 15px);
  }
}

.products-item__title {
  text-align: center;
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.15;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .products-item__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1280px) {
  .products-item__title {
    font-size: 20px;
  }
}

.products-item__text {
  text-align: center;
  letter-spacing: .04em;
  color: #fff;
  flex-grow: 1;
  margin-bottom: 22px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.52;
}

@media only screen and (min-width: 1280px) {
  .products-item__text {
    margin-bottom: 20px;
  }
}

.courses {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .courses {
    background-image: url("lines.d296ca4c.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 130%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.courses .courses__label-top {
  text-align: left;
}

.courses .courses__title {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #22534e;
  margin-bottom: 10px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.15;
}

@media only screen and (min-width: 768px) {
  .courses .courses__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1280px) {
  .courses .courses__title {
    font-size: 58px;
  }
}

.courses .courses__label {
  color: #437e79;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.17;
}

@media only screen and (min-width: 768px) {
  .courses .courses__label {
    max-width: 50%;
  }
}

@media only screen and (min-width: 1280px) {
  .courses .courses__label {
    font-size: 20px;
  }
}

.courses .courses__list {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) {
  .courses .courses__list {
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
  }
}

.courses .courses-item {
  width: 290px;
  cursor: pointer;
  background-color: #22534e;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 10px;
  transition: all .3s linear;
  display: flex;
  position: relative;
  overflow: hidden;
}

.courses .courses-item img {
  object-position: center;
  object-fit: contain;
  max-width: 100%;
  display: block;
}

.courses .courses-item:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 30px #d414431a;
}

.courses .courses-item .courses-content {
  height: 100%;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.courses .courses-item .courses-content .courses-item__title {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.15;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .courses .courses-item .courses-content .courses-item__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1280px) {
  .courses .courses-item .courses-content .courses-item__title {
    font-size: 20px;
  }
}

.courses .courses-item .courses-content .courses-item__text {
  letter-spacing: .04em;
  color: #fff;
  flex: 1;
  margin-bottom: 22px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.52;
}

@media only screen and (min-width: 1280px) {
  .courses .courses-item .courses-content .courses-item__text {
    margin-bottom: 20px;
  }
}

.courses .courses-item .courses-content .courses-item__price {
  color: #fff;
  margin-bottom: 25px;
  font-size: 18px;
}

.courses .courses-item .courses-content .courses-item__price span {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
}

.courses .courses-item .courses-content .buttons .button {
  flex-direction: column;
  display: flex;
}

.courses .courses-item .courses-content .buttons .button:first-child {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .courses .courses-item {
    width: calc(50% - 10px);
    margin: 0;
  }

  .courses .courses-item .buttons {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .courses .courses-item .buttons .button {
    display: inline-block;
  }

  .courses .courses-item .buttons .button:first-child {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1280px) {
  .courses .courses-item {
    width: calc(33.3333% - 20px);
  }
}

.home .price__text-style {
  text-transform: uppercase;
  letter-spacing: .04em;
  padding: 10px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
}

@media only screen and (min-width: 768px) {
  .home .price__text-style {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1280px) {
  .home .price__text-style {
    font-size: 16px;
  }
}

.home .price-card__title {
  color: #fff;
  margin-bottom: 20px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .home .price-card__title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1280px) {
  .home .price-card__title {
    margin-bottom: 60px;
    font-size: 58px;
  }
}

.home .price {
  background-color: #22534e;
  padding: 60px 0;
}

.home .price__group {
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  display: flex;
}

.home .price-card {
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 8px 30px #d414431a;
}

.home .price-card__class {
  min-height: 60px;
  color: #fff;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
}

@media only screen and (max-width: 767.98px) {
  .home .price__container {
    padding-top: 111px;
    padding-bottom: 48px;
  }

  .home .price__group {
    max-width: 280px;
    bottom: 178px;
  }
}

@media only screen and (min-width: 768px) {
  .home .price__container {
    padding: 72px 18px 40px;
  }

  .home .price__group {
    max-width: 732px;
    flex-direction: row;
    bottom: 124px;
  }

  .home .price-card {
    width: calc(50% - 16px);
  }

  .home .price-card__class {
    text-transform: uppercase;
    padding: 0 16px;
    font-size: 22px;
    font-weight: 700;
  }
}

@media only screen and (min-width: 1280px) {
  .home .price__container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .home .price__group {
    max-width: 1248px;
    margin-bottom: 60px;
  }

  .home .price-card {
    width: calc(33.3333% - 10.6667px);
  }

  .home .price-card__class {
    padding: 10px 16px;
    font-size: 28px;
  }
}

.home .listing-item {
  height: 100%;
  cursor: pointer;
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.home .listing-item .listing {
  background-color: #fff;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  flex-grow: 1;
  padding: 20px;
  position: relative;
}

.home .listing-item .listing:before {
  content: "";
  z-index: 9;
  border-bottom: 20px solid #fff;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  position: absolute;
  top: -15px;
  left: calc(50% - 15px);
}

.home figure.image img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
}

.home figure.image {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.home figure.image figcaption {
  width: 100%;
  height: 250px;
  text-align: center;
  background-color: #0009;
  transition: background-color .2s ease-in;
  position: absolute;
  top: 0;
  bottom: 4px;
  overflow: hidden;
}

.home figcaption .caption {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.home .listing h4 {
  text-align: center;
  padding: 5px 10px;
  font-size: 10px;
}

.home .listing h4:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.home .listing-item:hover figure.image figcaption {
  background: #c3272b99;
}

.home .thead__title {
  color: #fff;
  background: #437e79;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 14px;
}

.home table th {
  color: #000;
  text-transform: uppercase;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
}

.home table th:not(:first-child) {
  text-align: right;
}

.home table tr:hover th, .home table tr:hover td {
  color: #fff;
  background-color: #437e79;
}

.home tr {
  color: gray;
  padding-bottom: 10px;
}

.home td {
  text-align: center;
  padding: 7px 5px;
  font-size: 12px;
}

.home table {
  width: 100%;
}

.home th {
  text-align: left;
  border-bottom: 1px solid #c1c1c14d;
  font-weight: 400;
}

.home tbody td {
  text-align: right;
  border-bottom: 1px solid #c1c1c14d;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.gallery {
  padding: 0;
}

.gallery__img {
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 767.98px) {
  .gallery__img {
    max-width: 364px;
  }
}

@media only screen and (max-width: 1279.98px) {
  .gallery__img {
    max-width: 786px;
  }
}

@media only screen and (max-width: 1279.98px) {
  .gallery__img {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 768px) {
  .customer-reviews {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 106px;
    position: relative;
  }
}

.customer-reviews .customer-reviews__container {
  padding-bottom: 60px;
}

.customer-reviews .customer-header {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .customer-reviews .customer-header {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    display: flex;
  }
}

.customer-reviews .customer-header .customer-reviews__title {
  margin-top: 40px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .customer-reviews .customer-header .customer-reviews__title {
    text-align: left;
    max-width: 400px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.customer-reviews .customer-header a {
  color: #437e79;
  font-size: 16px;
  text-decoration: none;
  transition: all .25s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.customer-reviews .customer-header a:hover {
  color: #c7133e;
}

.rewiews-item {
  box-sizing: border-box;
  border: 1px solid #22534e;
  border-radius: 10px;
  flex-direction: column;
  flex-shrink: 0;
  padding: 55px 30px 30px;
  display: flex;
  overflow: hidden;
}

.rewiews-item.review-scorecard {
  color: #fff;
  background-color: #c7133e;
  border-color: #0000;
  align-items: center;
  font-weight: 700;
}

.rewiews-item.review-scorecard .reviews-bal {
  font-size: 104px;
}

@media only screen and (min-width: 768px) {
  .rewiews-item.review-scorecard .reviews-bal {
    font-size: 64px;
  }
}

@media only screen and (min-width: 1280px) {
  .rewiews-item.review-scorecard .reviews-bal {
    font-size: 104px;
  }
}

.rewiews-item.review-scorecard .reviews-bal span {
  margin-left: 5px;
  font-size: 18px;
  display: inline-block;
}

.rewiews-item.review-scorecard .review__description {
  height: auto;
  font-weight: 400;
}

.rewiews-item.review-scorecard .review__description:after {
  background-image: none;
}

.rewiews-item .review__description {
  text-align: left;
  max-height: 220px;
  flex: 1;
  padding: 20px 0;
  font-size: 16px;
  line-height: 130%;
  position: relative;
  overflow: hidden;
}

.rewiews-item .review__description:after {
  content: "";
  height: 60px;
  filter: blur();
  background: linear-gradient(#0000, #fff);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.rewiews-item .reviewer {
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  display: flex;
}

.rewiews-item .reviewer__photo {
  margin-right: 15px;
}

.rewiews-item .reviewer__photo img {
  display: block;
}

.rewiews-item .reviewer____identification .reviewer__name {
  letter-spacing: -.01em;
  color: #000;
  margin-bottom: 7px;
  font-size: 18px;
  line-height: 100%;
}

.rewiews-item .reviewer____identification .reviewer__date {
  letter-spacing: -.01em;
  color: #000;
  opacity: .6;
  font-size: 16px;
  line-height: 100%;
}

.footer {
  background-color: #22534e;
  flex: none;
  font-style: normal;
}

.footer-wrapper {
  padding: 20px 18px;
}

.footer__container {
  padding-top: 40px;
}

.footer .footer__item .item {
  margin-bottom: 10px;
}

.footer .footer__item .item:hover .link {
  color: gray;
  border-color: gray;
}

.footer .footer__item .link {
  color: #fff;
  letter-spacing: 1px;
  padding: 7px 0;
  font-size: 16px;
  transition: all .3s ease-in;
}

.rights-reserved {
  width: 100%;
  border-top: 1px solid #fff;
  padding: 10px 0;
}

.rights-reserved .reserved-group {
  margin-bottom: 10px;
}

.rights-reserved__text {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.52;
}

.rights-reserved .links a {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}

.rights-reserved .payment {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rights-reserved .payment .pay {
  margin-right: 10px;
}

.rights-reserved .payment .pay:last-child {
  margin-right: 0;
}

.footer__title {
  color: #fff;
  margin-bottom: 30px;
  font-style: normal;
}

@media only screen and (max-width: 767.98px) {
  .footer__container {
    flex-direction: column;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
  }

  .handmade {
    margin-bottom: 40px;
  }

  .handmade__under-icon {
    width: 20px;
    height: 20px;
  }

  .socials__link {
    width: 32px;
    height: 32px;
  }

  .socials__box:not(:last-child) {
    margin-right: 28px;
  }

  .rights-reserved {
    display: block;
  }

  .footer__item:not(:last-child) {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .footer__container {
    padding-left: 18px;
    padding-right: 18px;
  }

  .footer-wrapper {
    justify-content: space-between;
    padding: 40px 18px;
    display: flex;
  }

  .footer-wrapper .footer__item {
    padding-right: 10px;
  }

  .footer-wrapper .footer__item:first-child {
    flex: 1 0 auto;
  }

  .footer-wrapper .footer__item:last-child {
    padding-right: 0;
  }

  .footer-wrapper .footer__item .item {
    margin-bottom: 10px;
  }

  .footer-wrapper .footer__item .item:hover .link {
    color: gray;
    border-color: gray;
  }

  .footer-wrapper .footer__item .link {
    color: #fff;
    letter-spacing: 1px;
    padding: 7px 0;
    font-size: 16px;
    transition: all .3s ease-in;
  }

  .handmade {
    max-width: 370px;
  }

  .handmade__upper-text {
    letter-spacing: .04em;
    margin-bottom: 12px;
    font-size: 30px;
    line-height: 1.13;
  }

  .handmade__under-text {
    letter-spacing: .06em;
    font-size: 58px;
    line-height: 1.14;
  }

  .handmade__under-icon {
    width: 40px;
    height: 40px;
  }

  .footer__title {
    text-align: left;
  }

  .socials__link {
    width: 34px;
    height: 34px;
  }

  .socials__box:not(:last-child) {
    margin-right: 30px;
  }

  .rights-reserved {
    text-align: center;
  }

  .rights-reserved__text {
    letter-spacing: .02em;
  }
}

@media only screen and (min-width: 1280px) {
  .footer__container {
    padding-bottom: 60px;
  }

  .footer__item {
    flex-grow: 1;
  }

  .rights-reserved, .rights-reserved .reserved-group {
    margin-bottom: 0;
  }

  .rights-reserved .inner {
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    display: flex;
  }
}

.footer-address {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .footer-address {
    display: block;
  }

  .footer-address .footer__title {
    text-align: left;
  }
}

.footer-address .footer__contact {
  flex-direction: column;
  align-items: flex-start;
  font-style: normal;
  display: flex;
}

.footer-address .footer__contact .contact__item {
  margin-bottom: 10px;
  margin-left: 0;
}

.footer-address .footer__contact .contact__item .icon {
  fill: #fff;
}

.footer-address .footer__contact .contact__item:hover .contact__link {
  color: #241e20;
}

.footer-address .footer__contact .contact__item:hover .icon {
  fill: #241e20;
}

.footer-address .footer__contact .contact__link {
  color: #fff;
  font-size: 14px;
}

.contact__map-wrap {
  overflow: hidden;
  box-shadow: 0 8px 30px #d414431a;
}

.contact__map-wrap .google-map {
  height: 350px;
  display: block;
  width: 100% !important;
}

.google-map {
  width: 100%;
  height: 250px;
  display: block;
}

.footer-social {
  align-items: center;
  margin: 0 0 20px;
  display: flex;
}

.footer-social .address-social__item {
  color: #fff;
  margin-right: 7px;
  font-size: 14px;
  font-style: normal;
}

.footer-social .address-social__item:last-child {
  margin-right: 0;
}

.contact__sheddule {
  flex-direction: column;
  display: flex;
}

.contact__sheddule .contact__item {
  margin-bottom: 10px;
}

.contact__sheddule .contact__sheddule-links {
  letter-spacing: .05rem;
  text-transform: uppercase;
  color: #fff;
}

.contact__sheddule .sheddule-time {
  font-size: 12px;
}

.contact__sheddule .contact__link {
  color: #fff;
  font-size: 14px;
  font-style: normal;
}

#top-banner {
  width: 100%;
  min-height: 35px;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#top-banner .splide__list {
  height: 35px;
}

#top-banner .splide__list .slide-1 {
  color: #fff;
  height: 100%;
  text-transform: uppercase;
  background-color: #d41342;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  display: flex;
}

#top-banner .splide__list .slide-1 span {
  margin-right: 5px;
  font-weight: 700;
}

#top-banner .splide__list .slide-2 {
  color: #fff;
  height: 100%;
  background-color: #d41342;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  display: flex;
}

#top-banner .splide__list .slide-2 a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

#top-banner .splide__list .slide-2 a span {
  font-style: italic;
  font-weight: 300;
}

.before-affter {
  background-color: #22534e;
  background-image: linear-gradient(#0b272466, #151616), url("desktop_hero@2x.1f98a0bc.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 150px 0;
}

@media only screen and (min-width: 768px) {
  .before-affter .inner {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.before-affter .inner .text-box {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .before-affter .inner .text-box {
    width: 50%;
    margin-bottom: 0;
    margin-right: 30px;
  }
}

.before-affter .inner .text-box .title {
  color: #fff;
  letter-spacing: .04em;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.15;
}

.before-affter .inner .text-box .text {
  color: #fff;
  text-align: left;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
}

.img-slider {
  max-width: 400px;
  width: 100%;
  height: 550px;
  border: 2px solid #fff;
  position: relative;
}

.img-slider .img {
  width: 100%;
  height: 100%;
  background-size: 900px 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.img-slider .background-img {
  background-image: url("IMG_9678.38158b28.jpg");
  background-size: cover;
}

.img-slider .foreground-img {
  width: 50%;
  background-image: url("IMG_9686.7564d7ef.jpg");
  background-size: cover;
}

.img-slider .slider {
  appearance: none;
  width: 100%;
  height: 100%;
  background: #f2f2f24d;
  outline: none;
  margin: 0;
  transition: all .2s;
  position: absolute;
}

.img-slider .slider:hover {
  background: #f2f2f21a;
}

.img-slider .slider::-webkit-slider-thumb {
  appearance: none;
  width: 6px;
  height: 550px;
  cursor: pointer;
  background: #fff;
}

.img-slider .slider::-moz-range-thumb {
  width: 6px;
  height: 550px;
  cursor: pointer;
  background: #fff;
}

.img-slider .slider-button {
  pointer-events: none;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  display: flex;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
}

.img-slider .slider-button:after {
  content: "";
  border: 2px solid #5d5d5d;
  border-width: 0 2px 2px 0;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  padding: 2px;
  display: inline-flex;
  transform: rotate(-45deg);
}

.img-slider .slider-button:before {
  content: "";
  border: 2px solid #5d5d5d;
  border-width: 0 2px 2px 0;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  padding: 2px;
  display: inline-flex;
  transform: rotate(135deg);
}

.goto-top {
  width: 50px;
  height: 50px;
  stroke: #437e79;
  fill: #437e79;
  color: #437e79;
  background-color: #0000;
  border: 2px solid #437e79;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.goto-top.goto-top--hide {
  transform: translateX(200%);
}

.goto-top__icon {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
}

.backdrop[data-modal="buynow"] {
  pointer-events: initial;
}

.backdrop[data-modal="buynow"] .buynow {
  visibility: visible;
  opacity: 1;
  pointer-events: initial;
  transform: translate(-50%, -50%)scale(1);
}

.buynow {
  max-width: calc(100% - 20px);
  width: 80%;
  background-color: #c2e297;
}

@media only screen and (min-width: 320px) {
  .buynow {
    background-position: left 15% bottom;
    background-repeat: no-repeat;
    background-size: 570px;
  }
}

@media only screen and (min-width: 320px) and (min-device-pixel-ratio: 2), only screen and (min-width: 320px) and (min-resolution: 192dpi), only screen and (min-width: 320px) and (min-resolution: 2x) {
  .buynow {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 570px;
  }
}

@media screen and (max-width: 767px) {
  .buynow {
    max-width: 380px;
  }
}

@media screen and (min-width: 800px) {
  .buynow {
    max-width: 700px !important;
  }
}

.modal-container {
  display: flex;
}

.modal-container .desc-title {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #22534e;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .modal-container .desc-title {
    text-align: center;
    font-size: 18px;
  }
}

.modal-container .desc-text {
  color: #fff;
  max-width: 400px;
  margin-bottom: 20px;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .modal-container .desc-text {
    font-size: 16px;
  }
}

.modal-container .desc .list {
  margin: 0 0 40px;
  padding: 0;
  list-style: none;
}

.modal-container .desc .list .item {
  color: #22534e;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
}

.modal-container .desc .list .item:before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg height='48' fill='%23fff' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h48v48h-48z' fill='none'/%3E%3Cpath d='M18 32.34l-8.34-8.34-2.83 2.83 11.17 11.17 24-24-2.83-2.83z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
  display: block;
}

.modal-container .img-wrap img {
  max-width: 500px;
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  display: block;
  mask-image: linear-gradient(0deg, #0000 0, #000c 50%);
}

@media screen and (max-width: 767px) {
  .modal-container .img-wrap {
    display: none;
  }
}

.backdrop[data-modal=""] {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  z-index: 11;
  pointer-events: none;
  background-color: #00000080;
  padding: 15px;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(1);
}

.modal {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  max-width: calc(100% - 20px);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  padding: 40px;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), transform .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%)scale(1.8);
  box-shadow: 0 1px 3px #0000001f, 0 1px 1px #00000024, 0 2px 1px #0003;
}

.modal__title {
  text-align: center;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.15;
  display: block;
}

.btn-close {
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;
}

.close-icon {
  width: 15px;
  height: 15px;
}

.pravila .inner-wrapper {
  padding: 50px 0;
}

.pravila .inner-wrapper .title {
  text-align: center;
  margin-bottom: 20px;
}

.pravila .inner-wrapper .list {
  padding: 10px 30px;
}

.pravila .inner-wrapper .list .item {
  margin-bottom: 20px;
}

.pravila .inner-wrapper .list .item ul li {
  padding-left: 30px;
  position: relative;
}

.pravila .inner-wrapper .list .item ul li:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"48\" fill=\"rgb(212, 19, 66)\" viewBox=\"0 -960 960 960\" width=\"48\"><path d=\"M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z\"/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.pravila .inner-wrapper .list .item .subtitle {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.pravila .inner-wrapper .list .item .subtext {
  color: #000;
  margin: 10px 0;
  font-size: 16px;
  font-style: italic;
}

.pravila .inner-wrapper .list .item .text {
  text-align: left;
  margin-bottom: 10px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  display: block;
}

@media only screen and (max-width: 767.98px) {
  .pravila .title {
    font-size: 24px;
  }
}

.price {
  padding: 0;
}

.price .banner {
  background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .price .banner {
    background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  }
}

@media screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), screen and (min-width: 1280px) and (min-resolution: 192dpi), screen and (min-width: 1280px) and (min-resolution: 2x) {
  .price .banner {
    background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  }
}

.price .banner__body {
  padding-top: 250px;
  padding-bottom: 250px;
}

@media screen and (min-width: 1280px) {
  .price .banner__body {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.price .hero__items {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .price .hero__items {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .price .hero__items {
    margin-bottom: 40px;
  }
}

.price .hero__title {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .price .hero__title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1279px) {
  .price .hero__title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .price .hero__title {
    margin-bottom: 24px;
    font-size: 54px;
  }

  .price .hero__title--accent {
    font-family: Montserrat, Helvetica, sans-serif;
  }
}

.price .hero__title--accent {
  letter-spacing: 0;
  text-transform: none;
  color: #fff;
  padding: 10px 0;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .price .hero__title--accent {
    font-size: 16px;
  }
}

.price .hero__text {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.price .hero__text--accent {
  padding-right: 10px;
}

@media screen and (min-width: 1280px) {
  .price .hero__text {
    font-size: 14px;
    line-height: 1.3;
  }

  .price .hero__text--accent {
    padding-right: 20px;
  }
}

.price .price-list {
  padding: 100px 0;
}

.price .price-list .price-item {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.price .price-list .price-item:last-child h2 {
  border-bottom: 1px solid #d3d3d3;
}

.price .price-list h2 {
  cursor: pointer;
  color: #22534e;
  text-transform: uppercase;
  background-color: #0000;
  border-top: 1px solid #d3d3d3;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  position: relative;
}

.price .price-list h2 span {
  margin-left: 10px;
}

.price .price-list h2 span svg {
  width: 20px;
  height: 20px;
  transition: all .5s;
}

@media screen and (max-width: 767px) {
  .price .price-list h2 {
    padding: 1em 0;
    font-size: 18px;
  }
}

.price .price-list .price-body {
  min-height: 0;
  height: 0;
  background-color: #fff;
  overflow: hidden;
  transition: all .5s !important;
}

.price .price-list .price-body p {
  padding: 1rem 2em;
}

.price .price-list a {
  color: #4682b4;
}

.price .price-list .price-item.active .price-body {
  padding-bottom: 10px;
  transition: all .5s;
}

.price .price-list .price-item.active h2 svg {
  transform: rotate(180deg);
}

.price .price-table .thead__title {
  color: #fff;
  background: #437e79;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}

.price .price-table table th {
  color: #000;
  text-transform: uppercase;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .price .price-table table th {
    font-size: 12px;
  }
}

.price .price-table table th:not(:first-child) {
  text-align: right;
}

.price .price-table table tr th, .price .price-table table tr td {
  transition: all .3s;
}

.price .price-table table tr:hover th, .price .price-table table tr:hover td {
  background-color: #eee;
}

.price .price-table tr {
  color: gray;
  padding-bottom: 10px;
}

.price .price-table td {
  text-align: center;
  padding: 7px 5px;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .price .price-table td {
    font-size: 12px;
  }
}

.price .price-table table {
  width: 100%;
}

.price .price-table th {
  text-align: left;
  border-bottom: 1px solid #c1c1c14d;
  font-weight: 400;
}

.price .price-table tbody td {
  text-align: right;
  border-bottom: 1px solid #c1c1c14d;
}

.price .price-table tfoot th {
  border: none;
}

.politics .inner-wrapper {
  padding: 50px 0;
}

.politics .inner-wrapper .title {
  text-align: center;
  margin-bottom: 20px;
}

.politics .inner-wrapper .list {
  padding: 10px 30px;
}

.politics .inner-wrapper .list .item {
  margin-bottom: 20px;
}

.politics .inner-wrapper .list .item ul li {
  padding-left: 30px;
  position: relative;
}

.politics .inner-wrapper .list .item ul li:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"48\" fill=\"rgb(212, 19, 66)\" viewBox=\"0 -960 960 960\" width=\"48\"><path d=\"M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z\"/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 10px;
  left: 5px;
}

.politics .inner-wrapper .list .item .subtitle {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.politics .inner-wrapper .list .item .subtext {
  color: #000;
  margin: 10px 0;
  font-size: 16px;
  font-style: italic;
}

.politics .inner-wrapper .list .item .text {
  text-align: left;
  margin-bottom: 10px;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  display: block;
}

@media only screen and (max-width: 767.98px) {
  .politics .title {
    font-size: 24px;
  }
}

.masters .banner {
  background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .masters .banner {
    background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  }
}

@media screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), screen and (min-width: 1280px) and (min-resolution: 192dpi), screen and (min-width: 1280px) and (min-resolution: 2x) {
  .masters .banner {
    background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  }
}

.masters .banner__body {
  padding-top: 250px;
  padding-bottom: 250px;
}

@media screen and (min-width: 1280px) {
  .masters .banner__body {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.masters .hero__items {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .masters .hero__items {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .masters .hero__items {
    margin-bottom: 40px;
  }
}

.masters .hero__title {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .masters .hero__title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1279px) {
  .masters .hero__title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .masters .hero__title {
    margin-bottom: 24px;
    font-size: 54px;
  }

  .masters .hero__title--accent {
    font-family: Montserrat, Helvetica, sans-serif;
  }
}

.masters .hero__title--accent {
  letter-spacing: 0;
  text-transform: none;
  color: #fff;
  padding: 10px 0;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .masters .hero__title--accent {
    font-size: 16px;
  }
}

.masters .hero__text {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.masters .hero__text--accent {
  padding-right: 10px;
}

@media screen and (min-width: 1280px) {
  .masters .hero__text {
    font-size: 14px;
    line-height: 1.3;
  }

  .masters .hero__text--accent {
    padding-right: 20px;
  }
}

.masters .hero__product {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 767px) {
  .masters .hero__product {
    display: none;
  }
}

.masters .product__text {
  max-width: 300px;
  color: #fff;
  margin-bottom: 7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (min-width: 1280px) {
  .masters .product__text {
    max-width: 300px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 140%;
  }
}

.masters .product__body .button {
  width: 18px;
  height: 18px;
  padding: 0;
}

@media screen and (min-width: 1280px) {
  .masters .product__body .button {
    width: 26px;
    height: 26px;
    padding: 0;
  }
}

.masters .product__icon {
  width: 10px;
  height: 10px;
  fill: #22534e;
  padding: 0;
}

.masters .buttons {
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .masters .buttons .portfolio__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .masters .buttons .portfolio__items {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .masters .buttons .portfolio__items .portfolio__item {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .masters .buttons .portfolio__items .portfolio__item {
    margin-bottom: 0;
  }
}

.masters .buttons .portfolio__btn {
  color: #000;
  letter-spacing: .03em;
  cursor: pointer;
  background-color: #f5f4fa;
  border: 0;
  border-radius: 4px;
  margin: 0 4px;
  padding: 6px 22px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  transition: color .25s cubic-bezier(.4, 0, .2, 1), background-color .25s cubic-bezier(.4, 0, .2, 1), box-shadow .25s cubic-bezier(.4, 0, .2, 1);
}

.masters .buttons .portfolio__btn:hover, .masters .buttons .portfolio__btn:focus, .masters .buttons .portfolio__btn.active {
  color: #fff;
  background-color: #d41342;
  box-shadow: 0 5px 15px #75757533;
}

.masters .section-masters {
  padding: 50px 0;
}

.masters .portfolio__masters {
  flex-wrap: wrap;
  gap: 30px;
  display: flex;
}

.masters .masters__item {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .masters .masters__item {
    width: 100%;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 5px;
    transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1);
    transform: scale(1);
  }
}

@media screen and (min-width: 768px) {
  .masters .masters__item {
    width: calc(50% - 15px);
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 5px;
    transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1);
    transform: scale(1);
  }
}

@media screen and (min-width: 1280px) {
  .masters .masters__item {
    width: calc(33.3333% - 20px);
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 5px;
    transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1);
    transform: scale(1);
  }
}

.masters .masters__inner {
  padding-bottom: 120%;
  position: relative;
  overflow: hidden;
}

.masters .masters__inner img {
  object-fit: cover;
  object-position: center;
  height: 120%;
  width: 120%;
  display: block;
  position: absolute;
  inset: 0 0 0 -5%;
}

.masters .masters__content {
  flex-direction: column;
  padding: 20px 24px;
  display: flex;
}

.masters .masters__content .masters__title {
  letter-spacing: .06em;
  color: #000;
  flex: auto;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 2;
}

.masters .masters__content .masters__subtitle {
  color: gray;
  letter-spacing: .03em;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

body.faq {
  padding: 0;
}

body.faq .banner {
  background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  body.faq .banner {
    background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  }
}

@media screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), screen and (min-width: 1280px) and (min-resolution: 192dpi), screen and (min-width: 1280px) and (min-resolution: 2x) {
  body.faq .banner {
    background-image: linear-gradient(#22534e66, #22534eb3), url("masters_banner.86cbe753.webp");
  }
}

body.faq .banner__body {
  padding-top: 250px;
  padding-bottom: 250px;
}

@media screen and (min-width: 1280px) {
  body.faq .banner__body {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

body.faq .hero__items {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  body.faq .hero__items {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  body.faq .hero__items {
    margin-bottom: 40px;
  }
}

body.faq .hero__title {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  body.faq .hero__title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1279px) {
  body.faq .hero__title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1280px) {
  body.faq .hero__title {
    margin-bottom: 24px;
    font-size: 54px;
  }

  body.faq .hero__title--accent {
    font-family: Montserrat, Helvetica, sans-serif;
  }
}

body.faq .hero__title--accent {
  letter-spacing: 0;
  text-transform: none;
  color: #fff;
  padding: 10px 0;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  body.faq .hero__title--accent {
    font-size: 16px;
  }
}

body.faq .hero__text {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

body.faq .hero__text--accent {
  padding-right: 10px;
}

@media screen and (min-width: 1280px) {
  body.faq .hero__text {
    font-size: 14px;
    line-height: 1.3;
  }

  body.faq .hero__text--accent {
    padding-right: 20px;
  }
}

body.faq .faq-list {
  padding: 100px 0;
}

body.faq .faq-list .faq-item {
  margin: 0;
  position: relative;
  overflow: hidden;
}

body.faq .faq-list .faq-item:last-child h2 {
  border-bottom: 1px solid #d3d3d3;
}

body.faq .faq-list h2 {
  cursor: pointer;
  color: #22534e;
  text-transform: uppercase;
  background-color: #0000;
  border-top: 1px solid #d3d3d3;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  position: relative;
}

body.faq .faq-list h2 span {
  margin-left: 10px;
}

body.faq .faq-list h2 span svg {
  transition: all .5s;
  width: 20px !important;
  height: 20px !important;
}

@media screen and (max-width: 767px) {
  body.faq .faq-list h2 {
    padding: 1rem 0;
    font-size: 16px;
  }
}

body.faq .faq-body {
  min-height: 0;
  height: 0;
  background-color: #fff;
  overflow: hidden;
  transition: all .5s !important;
}

body.faq .faq-body p {
  color: #22534e;
  margin: 0 0 1rem;
  padding: 1rem 2em;
  font-size: .95rem;
  font-weight: 500;
  line-height: 1.62;
}

@media screen and (max-width: 767px) {
  body.faq .faq-body p {
    padding: 1rem 0;
  }
}

body.faq .faq-item.active .faq-body {
  padding-bottom: 10px;
  transition: all .5s;
}

body.faq .faq-item.active h2 svg {
  transform: rotate(180deg);
}

.section-contacts {
  padding: 80px 0;
}

@media screen and (max-width: 1280px) {
  .section-contacts {
    padding: 20px 0;
  }
}

.section-contacts .contact__list {
  display: flex;
}

@media screen and (max-width: 992px) {
  .section-contacts .contact__list {
    display: block;
  }
}

.section-contacts .contact__list .contact__item {
  max-width: 50%;
  width: 100%;
  padding: 20px;
}

@media screen and (max-width: 992px) {
  .section-contacts .contact__list .contact__item {
    max-width: 100%;
    width: 100%;
  }
}

.section-contacts .contact__list .contact__item .contact__title {
  text-align: left;
  margin-bottom: 10px;
  font-size: 32px;
}

@media screen and (max-width: 776px) {
  .section-contacts .contact__list .contact__item .contact__title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
}

.section-contacts .contact__list .contact__item .text {
  text-align: left;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.3;
}

@media screen and (max-width: 776px) {
  .section-contacts .contact__list .contact__item .text {
    text-align: center;
  }
}

.section-contacts .contact__list .contact__item .address__list {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 776px) {
  .section-contacts .contact__list .contact__item .address__list {
    display: block;
  }
}

.section-contacts .contact__list .contact__item .address__list .address__item {
  max-width: 50%;
  width: 100%;
  padding: 10px;
}

@media screen and (max-width: 776px) {
  .section-contacts .contact__list .contact__item .address__list .address__item {
    max-width: 100%;
  }
}

.section-contacts .contact__list .contact__item .address__list .address__item table {
  border: none;
  margin-top: 10px;
}

.section-contacts .contact__list .contact__item .address__list .address__item table tbody tr td {
  color: #000;
  text-align: left;
  border-bottom: #0000;
  padding: 5px 0;
  font-size: 14px;
  background-color: #0000 !important;
}

.section-contacts .contact__list .contact__item .address__list .address__item table tbody tr td:hover {
  color: #000;
  background-color: #0000 !important;
}

.section-contacts .contact__list .contact__item .address__list .address__item table tbody tr td:nth-child(2) {
  text-align: right;
}

.section-contacts .contact__list .contact__item .address__list .address__title {
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
}

.section-contacts .contact__list .contact__item .address__list .list.soccial {
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.section-contacts .contact__list .contact__item .address__list .list .item {
  padding: 5px 0;
}

.section-contacts .contact__list .contact__item .address__list .list .item .link {
  color: #000;
  font-size: 14px;
  font-style: normal;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.section-contacts .contact__list .contact__item .address__list .list .item .link:hover {
  color: #22534e;
}

.section-contacts .contact__list .contact__item .address__list .list .item .link .icon {
  fill: #000;
  width: 30px;
  height: 30px;
  display: block;
}

.section-contacts .contact__list .contact__item .img-thumb {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 8px #0000003d;
}

.section-contacts .contact__list .contact__item .img-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=contacts.c81bbf54.css.map */
