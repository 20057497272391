.courses {
	padding-top: 40px;
	padding-bottom: 40px;

	@include breakpointFrom(tablet) {
		padding-top: 60px;
		padding-bottom: 60px;

		background-image: url('../../images/beauty-png/lines.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 130%;
	}

	.courses__label-top {
		text-align: left;
		// margin-left: 50px;

		@include breakpointFrom(tablet) {
			// margin-left: 233px;
		}

		@include breakpointFrom(desktop) {
			// margin-left: 480px;
		}
	}

	.courses__title {
		font-family: $main-font;
		font-weight: 700;
		font-size: 36px;
		line-height: 1.15;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $brand-color;
		margin-bottom: 10px;

		@include breakpointFrom(tablet) {
			font-size: 48px;
		}

		@include breakpointFrom(desktop) {
			font-size: 58px;
		}
	}

	.courses__label {
		font-size: 18px;
		line-height: 1.17;
		color: $brand-accent;
		margin-bottom: 20px;
		width: 100%;

		@include breakpointFrom(tablet) {
			max-width: 50%;
		}

		@include breakpointFrom(desktop) {
			font-size: 20px;
		}
	}

	.courses__list {
		margin-top: 50px;

		@include breakpointFrom(tablet) {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
		}
	}

	.courses-item {
		position: relative;
		display: flex;
		flex-direction: column;

		background-color: $brand-color;

		border-radius: 10px;
		width: 290px;
		cursor: pointer;
		overflow: hidden;

		margin-bottom: 10px;
		transition: all 0.3s linear;

		img {
			display: block;
			object-position: center;
			object-fit: contain;
			max-width: 100%;
		}


		&:hover {
			box-shadow: $img-shadow-accent;
			transform: scale(1.02);
		}

		.courses-content {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: 20px;

			.courses-item__title {
				position: relative;
				font-family: $main-font;
				font-weight: 400;
				font-size: 18px;
				line-height: 1.15;
				letter-spacing: 0.04em;
				text-transform: uppercase;
				color: $white;
				margin-bottom: 30px;

				@include breakpointFrom(tablet) {
					font-size: 24px;
				}

				@include breakpointFrom(desktop) {
					font-size: 20px;
				}
			}

			.courses-item__text {
				flex: 1;
				font-family: $main-font;
				font-weight: 400;
				font-size: 12px;
				line-height: 1.52;
				letter-spacing: 0.04em;
				color: $white;
				margin-bottom: 22px;
				flex-grow: 1;

				@include breakpointFrom(desktop) {
					margin-bottom: 20px;
				}
			}

			.courses-item__price {
				color: $white;
				font-size: 18px;
				margin-bottom: 25px;

				span {
					display: inline-block;
					font-size: 20px;
					font-weight: 500;
					margin-left: 10px;
				}
			}

			.buttons {
				.button {
					display: flex;
					flex-direction: column;
					// width: 100%;

					&:first-child {
						margin-bottom: 10px;
					}
				}
			}
		}

		@include breakpointFrom(tablet) {
			width: calc((100% - 20px) / 2);

			margin: 0;

			.buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.button {
					display: inline-block;

					&:first-child {
						margin-bottom: 10px;
					}
				}
			}
		}

		@include breakpointFrom(desktop) {
			width: calc((100% - 60px) / 3);
		}
	}


}