.price {
	padding: 0;

	.banner {
		position: relative;
		overflow: hidden;
		background-image: linear-gradient(to bottom,
				rgba(34, 83, 78, 0.4),
				rgba(34, 83, 78, 0.7)),
			url('../../images/hero/desktop/masters_banner.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		@media screen and (min-width: 1280px) {
			background-image: linear-gradient(to bottom,
					rgba(34, 83, 78, 0.4),
					rgba(34, 83, 78, 0.7)),
				url('../../images/hero/desktop/masters_banner.webp');

			@media (min-device-pixel-ratio: 2),
			(min-resolution: 192dpi),
			(min-resolution: 2dppx) {
				background-image: linear-gradient(to bottom,
						rgba(34, 83, 78, 0.4),
						rgba(34, 83, 78, 0.7)),
					url('../../images/hero/desktop/masters_banner.webp');
			}
		}
	}

	.banner__body {
		padding-top: 250px;
		padding-bottom: 250px;

		@media screen and (min-width: 1280px) {
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}

	.hero__items {
		margin-bottom: 20px;

		@media screen and (min-width: 768px) {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@media screen and (min-width: 1280px) {
			margin-bottom: 40px;
		}
	}

	.hero__title {
		line-height: 1.2;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $white;
		text-align: center;

		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
		}

		@media screen and (max-width: 1279px) {
			font-size: 32px;
		}

		@media screen and (min-width: 1280px) {
			font-size: 54px;
			margin-bottom: 24px;

			&--accent {
				font-family: $main-font;
			}
		}

		&--accent {
			display: inline-block;
			font-family: $main-font;
			font-weight: 400;
			font-size: 18px;
			letter-spacing: 0;

			padding: 10px 0;

			text-transform: none;
			color: $white;

			@media screen and (max-width: 767px) {
				font-size: 16px;
			}

		}
	}

	.hero__text {
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 8px;
		line-height: 1.2;
		color: $white;
		text-align: center;

		&--accent {
			padding-right: 10px;
		}

		@media screen and (min-width: 1280px) {
			font-size: 14px;
			line-height: 1.3;

			&--accent {
				padding-right: 20px;
			}
		}
	}

	.price-list {
		padding: 100px 0;

		.price-item {
			overflow: hidden;
			position: relative;
			margin: 0;

			&:last-child {
				h2 {
					border-bottom: 1px solid lightgrey;
				}
			}
		}

		h2 {
			cursor: pointer;
			position: relative;
			color: $brand-color;
			text-transform: uppercase;
			padding: 1em;
			font-size: 24px;
			font-weight: 400;
			border-top: 1px solid lightgrey;
			background-color: transparent;

			display: flex;
			align-items: center;
			justify-content: space-between;

			span {
				margin-left: 10px;

				svg {
					width: 20px;
					height: 20px;
					transition: all 0.5s ease;
				}
			}

			@media screen and (max-width: 767px) {
				font-size: 18px;
				padding: 1em 0;

			}
		}

		.price-body {
			min-height: 0;
			height: 0;
			overflow: hidden;
			background-color: white;
			transition: all 0.5s ease !important;

			p {
				padding: 1rem 2em;
			}
		}

		a {
			color: steelblue;
		}

		.price-item {

			&.active {

				.price-body {
					padding-bottom: 10px;
					transition: all 0.5s ease;

				}

				h2 {
					svg {
						transform: rotate(180deg);
					}
				}
			}


		}
	}

	.price-table {

		.thead__title {
			font-size: 16px;
			padding: 10px;
			color: $white;

			background: $brand-accent;
			margin-bottom: 20px;
		}

		table th {
			color: rgb(0, 0, 0);
			font-weight: bold;
			text-transform: uppercase;
			padding: 5px;
			font-size: 16px;

			@media screen and (max-width: 767px) {
				font-size: 12px;
			}

			&:not(:first-child) {
				text-align: right;
			}
		}

		table tr th,
		table tr td {
			transition: all 0.3s ease;
		}

		table tr:hover th,
		table tr:hover td {
			background-color: #eee;
			// color: white;
		}

		tr {
			padding-bottom: 10px;
			color: grey;
		}

		td {
			padding: 7px 5px;
			text-align: center;
			font-size: 16px;

			@media screen and (max-width: 767px) {
				font-size: 12px;
			}
		}

		table {
			width: 100%;
		}

		th {
			text-align: left;
			font-weight: 400;

			border-bottom: solid 1px rgba(193, 193, 193, 0.3);
		}

		tbody td {
			text-align: right;
			border-bottom: solid 1px rgba(193, 193, 193, 0.3);
		}

		tfoot {
			th {
				border: none;
			}
		}
	}

}