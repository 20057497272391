@import './buynow';

.backdrop[data-modal=''] {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

.backdrop {
	position: fixed;
	left: 0;
	top: 0;
	padding: 15px;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	transform: scale(1);
	transition: makeTransition((opacity visibility));
	z-index: 11;
	pointer-events: none;
}

.modal {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transform: translate(-50%, 50%) scale(1.8);

	position: fixed;
	left: 50%;
	top: 50%;
	// width: 450px;
	max-width: calc(100% - 20px);
	padding: 40px;
	// color: $text-color;
	// background-color: $bg-light;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
		0px 2px 1px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	transition: makeTransition((opacity transform visibility));

	//   background-image: url('../images/about/spilledmilk1x-descktop.png');
	background-position: bottom;
	background-size: contain;
	background-repeat: no-repeat;

	&__title {
		display: block;
		text-align: center;
		margin-bottom: 12px;
		font-weight: 700;
		font-size: 20px;
		line-height: 1.15;
		// color: $text-color;
	}
}

.btn-close {
	padding: 0;
	//   fill: $subtext-color;
	position: absolute;
	top: 8px;
	right: 8px;

	&:hover,
	&:focus {
		// fill: $hover-bg-accent-color;
	}
}

.close-icon {
	width: 15px;
	height: 15px;
}

// @import './locations';
// @import './franchise';
// @import './icecream';
// @import './icecoffee';
// @import './milkshakes';
// @import './recipe';