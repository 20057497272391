.home {
	.price__text-style {
		font-weight: 300;
		text-transform: uppercase;
		font-size: 20px;
		padding: 10px;
		line-height: 1.2;
		letter-spacing: 0.04em;

		@include breakpointFrom(tablet) {
			font-size: 22px;
		}

		@include breakpointFrom(desktop) {
			font-size: 16px;
		}
	}

	.price-card__title {
		font-weight: 700;
		color: $white;
		margin-bottom: 20;

		@include breakpointFrom(tablet) {
			font-size: 48px;
		}

		@include breakpointFrom(desktop) {
			font-size: 58px;
			margin-bottom: 60px;
		}
	}

	.price {
		background-color: $brand-color;
		padding: 60px 0;

		&__group {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 16px;
		}

		&-card {
			box-shadow: $img-shadow-accent;
			border-radius: 24px;
			overflow: hidden;

			&__class {
				display: inline-flex;
				align-items: center;
				min-height: 60px;
				color: $white;
				border-radius: 4px;
			}
		}
	}

	@include breakpointTo(tablet) {
		.price {
			&__container {
				padding-top: 111px;
				padding-bottom: 48px;
			}

			&__group {
				max-width: 280px;
				bottom: 178px;
			}
		}
	}

	@include breakpointFrom(tablet) {
		.price {
			&__container {
				padding: 72px 18px 40px 18px;
			}

			&__group {
				max-width: 732px;
				flex-direction: row;
				bottom: 124px;
			}

			&-card {
				width: calc((100% - 16px * 2) / 2);

				&__class {
					font-size: 22px;
					font-weight: 700;
					padding: 0 16px;
					text-transform: uppercase;
				}
			}
		}
	}

	@include breakpointFrom(desktop) {
		.price {
			&__container {
				padding-top: 80px;
				padding-bottom: 80px;
			}

			&__group {
				max-width: 1248px;
				margin-bottom: 60px;
			}

			&-card {
				width: calc((100% - 16px * 2) / 3);

				&__class {
					font-size: 28px;
					padding: 10px 16px;
				}
			}
		}
	}

	.listing-item {
		height: 100%;
		display: flex;
		flex-direction: column;

		border-radius: 24px;
		overflow: hidden;

		cursor: pointer;

		.listing {
			position: relative;
			flex-grow: 1;
			padding: 20px;
			background-color: white;

			border-bottom-left-radius: 24px;
			border-bottom-right-radius: 24px;
			// overflow: hidden;

			&:before {
				content: '';
				position: absolute;
				z-index: 9;
				top: -15px;
				left: calc(50% - 15px);
				border-bottom: 20px solid rgb(255, 255, 255);
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
			}
		}
	}

	figure.image img {
		width: 100%;
		height: 250px;

		object-fit: cover;
		object-position: center;
	}

	figure.image {
		position: relative;
		margin: 0;
		padding: 0;

		overflow: hidden;
	}

	figure.image figcaption {
		position: absolute;
		top: 0;
		width: 100%;
		height: 250px;
		text-align: center;
		bottom: 4px;
		background-color: rgba(0, 0, 0, 0.6);
		overflow: hidden;
		transition: background-color 0.2s ease-in;
	}

	figcaption .caption {
		position: relative;
		top: 50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.listing h4 {
		font-size: 10px;
		text-align: center;
		padding: 5px 10px;

		&:not(:last-child) {
			border-bottom: 1px solid #ccc;
		}
	}

	.listing-item:hover figure.image figcaption {
		background: rgba(195, 39, 43, 0.6);
	}

	.thead__title {
		font-size: 14px;
		padding: 10px;
		color: $white;

		background: $brand-accent;
		margin-bottom: 20px;
	}

	table th {
		color: rgb(0, 0, 0);
		font-weight: bold;
		text-transform: uppercase;
		padding: 5px;
		font-size: 12px;

		&:not(:first-child) {
			text-align: right;
		}
	}

	table tr:hover th,
	table tr:hover td {
		background-color: $brand-accent;
		color: white;
	}

	tr {
		padding-bottom: 10px;
		color: grey;
	}

	td {
		padding: 7px 5px;
		text-align: center;
		font-size: 12px;
	}

	table {
		width: 100%;
	}

	th {
		text-align: left;
		font-weight: 400;

		border-bottom: solid 1px rgba(193, 193, 193, 0.3);
	}

	tbody td {
		text-align: right;
		border-bottom: solid 1px rgba(193, 193, 193, 0.3);
	}

}