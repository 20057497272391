.before-affter {
	padding: 150px 0;
	background-color: $brand-color;
	background-image: linear-gradient(to bottom,
			rgba(11, 39, 36, 0.4),
			rgb(21, 22, 22)),
		url('../../images/hero/desktop/desktop_hero@2x.webp');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;

	.inner {
		@include breakpointFrom(tablet) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.text-box {
			margin-bottom: 20px;

			@include breakpointFrom(tablet) {
				width: 50%;
				margin-right: 30px;
				margin-bottom: 0;

			}

			.title {
				color: white;
				margin-bottom: 20px;
				letter-spacing: .04em;
				text-transform: uppercase;
				font-family: Arial, Helvetica, sans-serif;
				font-size: 54px;
				font-weight: 700;
				line-height: 1.15;
			}

			.text {
				font-family: 'Montserrat';
				color: white;
				text-align: left;
				font-weight: 300;
				font-size: 16px;
			}
		}
	}
}

.img-slider {
	position: relative;
	max-width: 400px;
	width: 100%;
	height: 550px;
	border: 2px solid white;

	.img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: 900px 100%;
	}

	.background-img {
		background-image: url('../../images/before-affter/IMG_9678.jpg');
		background-size: cover;
	}

	.foreground-img {
		background-image: url('../../images/before-affter/IMG_9686.jpg');
		background-size: cover;
		width: 50%;
	}

	.slider {
		position: absolute;
		appearance: none;
		width: 100%;
		height: 100%;
		background: rgba(#f2f2f2, .3);
		outline: none;
		margin: 0;
		transition: all .2s;

		&:hover {
			background: rgba(#f2f2f2, .1);
		}

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 6px;
			height: 550px;
			background: white;
			cursor: pointer;
		}

		&::-moz-range-thumb {
			width: 6px;
			height: 550px;
			background: white;
			cursor: pointer;
		}
	}

	.slider-button {
		$size: 30px;
		display: flex;
		align-items: center;
		pointer-events: none;
		position: absolute;
		width: $size;
		height: $size;
		border-radius: 50%;
		background-color: white;
		left: calc(50% - 18px);
		top: calc(50% - 18px);

		@mixin arrow-helper() {
			content: '';
			padding: 2px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-left: 6px;
			border: solid #5D5D5D;
			border-width: 0 2px 2px 0;
		}

		&:after {
			@include arrow-helper();
			transform: rotate(-45deg);
		}

		&:before {
			@include arrow-helper();
			transform: rotate(135deg);
		}
	}
}