.advantages {
	padding-top: 40px;
	padding-bottom: 40px;

	@include breakpointFrom(tablet) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.advantages__wrapper {
	@include breakpointFrom(tablet) {
		display: flex;
		align-items: center;
		gap: 5;
	}
}

.advantages-box {
	width: 100%;
	height: 300px;

	background-image: url(../../images/beauty-png/masters.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	border-radius: 20px;

	padding: 40px 20px;
	margin-bottom: 20px;

	box-shadow: $img-shadow-accent;

	@include breakpointFrom(tablet) {
		width: 50%;
		height: 300px;

		padding: 50px 20px;
		margin: 0;
	}

	@include breakpointFrom(desktop) {
		width: 650px;
		height: 450px;

		padding: 80px 40px;
	}
}

.advantages-box__text,
.advantages-box__title,
.advantages-box__subtext {
	text-align: left;
	color: $white;
}

.advantages-box__text {
	font-size: 12px;
	letter-spacing: 0.02em;
	line-height: 1.4;

	@include breakpointFrom(tablet) {
		font-size: 14px;
	}

	@include breakpointFrom(desktop) {
		font-size: 18px;
	}
}

.advantages-box__title {
	color: $white;
	margin-bottom: 20px;

	@include breakpointFrom(tablet) {
		font-size: 32px;
		line-height: 1.15;
		margin-bottom: 13px;
	}

	@include breakpointFrom(desktop) {
		font-size: 58px;
		line-height: 1.14;
		margin-bottom: 16px;
	}
}

.advantages-box__subtext {
	font-family: $main-font;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: 0.02em;

	@include breakpointFrom(tablet) {
		font-size: 16px;
	}

	@include breakpointFrom(desktop) {
		font-size: 18px;
	}
}

.advantages__list {
	display: block;

	@include breakpointFrom(tablet) {
		display: flex;
	}
}

.advantages {

	&__item {
		position: relative;
		width: 100%;

		@include breakpointFrom(mobile) {
			width: 230px;
			padding-top: 60px;

			&:not(:last-child) {
				margin-bottom: 32px;
			}
		}

		@include breakpointFrom(tablet) {
			width: 180px;
			padding-top: 86px;

			&:not(:last-child) {
				margin-right: 22px;
			}
		}

		@include breakpointFrom(desktop) {
			width: 188px;
			padding-top: 90px;
		}

		&::before {
			content: ' ';
			position: absolute;

			display: inline-block;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	&__text {
		font-family: $main-font;
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 1.67;
		letter-spacing: 0.02em;

		color: $black;

		@media screen and (min-width: 768px) {
			font-size: 14px;
			line-height: 1.71;
		}

		@media screen and (min-width: 1280px) {
			font-size: 16px;
			line-height: 1.75;
		}
	}
}

// Slider
.center {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	overflow: hidden;

	@include breakpointFrom(tablet) {
		margin-left: 3px;
	}

	@include breakpointFrom(desktop) {
		width: 100%;
	}
}

.wrapper {
	display: flex;
	width: 17em;

	margin-bottom: 20px;

	@include breakpointFrom(tablet) {
		width: 50em;
	}

	@include breakpointFrom(desktop) {
		width: 50em;
	}

	&>.inner {
		display: flex;
		gap: 1em;
		transition: all 1s ease-in-out;
	}
}

.card {
	overflow: hidden;
	width: 160px;
	height: 350px;

	border-radius: 0.5em;
	margin-bottom: 40px;

	.masters__inner {
		position: relative;
		overflow: hidden;
		padding-bottom: 120%;

		img {
			position: absolute;
			top: 0;
			bottom: 0;
			left: -5%;
			right: 0;
			display: block;
			object-fit: cover;
			object-position: center;
			height: 120%;
			width: 120%;
		}
	}

	.masters__content {
		display: flex;
		flex-direction: column;

		padding: 10px;

		.masters__title {
			font-weight: 700;
			line-height: 2;
			letter-spacing: 0.06em;
			font-size: 18px;

			color: black;
			margin-bottom: 4px;

			flex: 1 1 auto;
		}

		.masters__subtitle {
			font-weight: 400;
			color: grey;
			line-height: 1.2;
			letter-spacing: 0.03em;
			font-size: 14px;
		}
	}


	@include breakpointFrom(tablet) {
		width: 100%;
		height: 350px;
		margin: 0;
	}

	@include breakpointFrom(desktop) {
		width: 200px;
		min-height: 357px;
		height: 100%;
	}
}