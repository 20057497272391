.masters {

	.banner {
		position: relative;
		overflow: hidden;
		background-image: linear-gradient(to bottom,
				rgba(34, 83, 78, 0.4),
				rgba(34, 83, 78, 0.7)),
			url('../../images/hero/desktop/masters_banner.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		@media screen and (min-width: 1280px) {
			background-image: linear-gradient(to bottom,
					rgba(34, 83, 78, 0.4),
					rgba(34, 83, 78, 0.7)),
				url('../../images/hero/desktop/masters_banner.webp');

			@media (min-device-pixel-ratio: 2),
			(min-resolution: 192dpi),
			(min-resolution: 2dppx) {
				background-image: linear-gradient(to bottom,
						rgba(34, 83, 78, 0.4),
						rgba(34, 83, 78, 0.7)),
					url('../../images/hero/desktop/masters_banner.webp');
			}
		}
	}

	.banner__body {
		padding-top: 250px;
		padding-bottom: 250px;

		@media screen and (min-width: 1280px) {
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}

	.hero__items {
		margin-bottom: 20px;

		@media screen and (min-width: 768px) {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@media screen and (min-width: 1280px) {
			margin-bottom: 40px;
		}
	}

	.hero__title {
		line-height: 1.2;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $white;
		text-align: center;

		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
		}

		@media screen and (max-width: 1279px) {
			font-size: 32px;
		}

		@media screen and (min-width: 1280px) {
			font-size: 54px;
			margin-bottom: 24px;

			&--accent {
				font-family: $main-font;
			}
		}

		&--accent {
			display: inline-block;
			font-family: $main-font;
			font-weight: 400;
			font-size: 18px;
			letter-spacing: 0;

			padding: 10px 0;

			text-transform: none;
			color: $white;

			@media screen and (max-width: 767px) {
				font-size: 16px;
			}

		}
	}

	.hero__text {
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 8px;
		line-height: 1.2;
		color: $white;
		text-align: center;

		&--accent {
			padding-right: 10px;
		}

		@media screen and (min-width: 1280px) {
			font-size: 14px;
			line-height: 1.3;

			&--accent {
				padding-right: 20px;
			}
		}
	}

	.hero__product {
		display: flex;
		align-items: center;

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	.product__text {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
		max-width: 300px;
		color: $white;
		margin-bottom: 7px;

		@media screen and (min-width: 1280px) {
			max-width: 300px;
			width: 100%;
			font-size: 14px;
			line-height: 140%;
			margin-bottom: 20px;
		}
	}

	.product__body .button {
		width: 18px;
		height: 18px;
		padding: 0;

		@media screen and (min-width: 1280px) {
			width: 26px;
			height: 26px;
			padding: 0;
		}
	}

	.product__icon {
		width: 10px;
		height: 10px;
		padding: 0;
		fill: $brand-color;
	}

	// Button

	.buttons {
		padding: 40px 0;

		.portfolio__items {
			@media screen and (max-width: 767px) {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
			}

			@media screen and (min-width: 768px) {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
			}

			.portfolio__item {
				@media screen and (max-width: 767px) {
					margin-bottom: 15px;
				}

				@media screen and (min-width: 768px) {
					margin-bottom: 0;
				}
			}
		}

		.portfolio__btn {
			font-weight: 500;
			color: black;
			font-size: 16px;
			line-height: 1.6;
			letter-spacing: 0.03em;

			background-color: rgba(245, 244, 250, 1);
			padding: 6px 22px;
			border-radius: 4px;
			border: 0;

			margin: 0 4px;
			cursor: pointer;

			transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1),
				background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
				box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

			&:hover,
			&:focus,
			&.active {
				color: white;
				background-color: $alternate-accent-color;
				box-shadow: 0 5px 15px rgba(117, 117, 117, 0.2);
			}
		}
	}

	// Portfolio masters
	.section-masters {
		padding: 50px 0;
	}

	.portfolio__masters {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
	}

	.masters__item {
		overflow: hidden;

		@media screen and (max-width: 767px) {
			width: 100%;
			border: 1px solid rgba(238, 238, 238, 1);
			border-radius: 5px;
			transform: scale(1);
			transition: transform 1s ease;

			cursor: pointer;

			border: 1px solid rgba(238, 238, 238, 1);
			transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
		}

		@media screen and (min-width: 768px) {
			width: calc((100% - 30px) / 2);

			border-radius: 5px;
			transform: scale(1);
			transition: transform 1s ease;

			cursor: pointer;

			border: 1px solid rgba(238, 238, 238, 1);
			transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
		}

		@media screen and (min-width: 1280px) {
			width: calc((100% - 2 * 30px) / 3);
			border-radius: 5px;
			transform: scale(1);
			transition: transform 1s ease;

			cursor: pointer;

			border: 1px solid rgba(238, 238, 238, 1);
			transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
		}
	}

	.masters__inner {
		position: relative;
		overflow: hidden;
		padding-bottom: 120%;

		img {
			position: absolute;
			top: 0;
			bottom: 0;
			left: -5%;
			right: 0;
			display: block;
			object-fit: cover;
			object-position: center;
			height: 120%;
			width: 120%;
		}
	}

	.masters__content {
		display: flex;
		flex-direction: column;

		padding: 20px 24px;

		.masters__title {
			font-weight: 700;
			line-height: 2;
			letter-spacing: 0.06em;
			font-size: 18px;

			color: black;
			margin-bottom: 4px;

			flex: 1 1 auto;
		}

		.masters__subtitle {
			font-weight: 400;
			color: grey;
			line-height: 1.3;
			letter-spacing: 0.03em;
			font-size: 16px;
		}
	}

}