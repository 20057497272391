.customer-reviews {
	@include breakpointFrom(tablet) {
		padding-top: 80px;
		padding-bottom: 80px;
		text-align: center;
		position: relative;
		padding-bottom: 106px;
	}

	.customer-reviews__container {
		padding-bottom: 60px;
	}

	.customer-header {
		margin-bottom: 20px;

		@include breakpointFrom(tablet) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 40px;
		}

		.customer-reviews__title {
			margin-bottom: 10px;
			margin-top: 40px;

			@include breakpointFrom(tablet) {
				text-align: left;
				max-width: 400px;
				margin-bottom: 0;
				margin-top: 0;
			}
		}

		a {
			display: inline-block;
			text-decoration: none;
			font-size: 16px;
			color: $brand-accent;

			transition: all $transition-style;

			&:hover {
				color: $bg-accent-color;
			}
		}

	}
}

.rewiews-item {
	padding: 55px 30px 30px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-shrink: 0;
	border-radius: 10px;
	border: 1px solid $brand-color;

	&.review-scorecard {
		color: $white;
		font-weight: 700;
		background-color: $bg-accent-color;
		align-items: center;
		border-color: transparent;

		.reviews-bal {
			font-size: 104px;

			@include breakpointFrom(tablet) {
				font-size: 64px;
			}

			@include breakpointFrom(desktop) {
				font-size: 104px;
			}

			span {
				margin-left: 5px;
				display: inline-block;
				font-size: 18px;
			}
		}

		.review__description {
			font-weight: 400;
			height: auto;

			&::after {
				background-image: none;
			}
		}
	}

	.review__description {
		position: relative;
		flex: 1;
		font-size: 16px;
		line-height: 130%;
		text-align: left;
		padding: 20px 0;

		max-height: 220px;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 60px;
			background: linear-gradient(rgba(0, 0, 0, 0), rgba(255, 255, 255, 1));
			filter: blur();
		}
	}

	.reviewer {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-top: 20px;

		&__photo {
			margin-right: 15px;

			img {
				display: block;
			}
		}

		&____identification {
			.reviewer__name {
				font-size: 18px;
				line-height: 100%;
				letter-spacing: -.01em;
				color: #000;
				margin-bottom: 7px;
			}

			.reviewer__date {
				font-size: 16px;
				line-height: 100%;
				letter-spacing: -.01em;
				color: #000;
				opacity: .6;
			}

		}
	}
}